<template>
  <div>
    <v-simple-table class="mt-5">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Senha</th>
          <th class="text-left">Nome</th>
          <th class="text-left">Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="paciente in pacientes"
            :key="paciente.nome"
            class="text-left"
        >
          <td>{{ paciente.Senha }}</td>
          <td>{{ paciente.UsuarioSenha }}</td>
          <td>{{ paciente.ClassificacaoAtendimento }}</td>
          <td>
            <v-btn
                small
                depressed
                color="success"
                @click="chamarSenha(paciente.IdChamadaInterna)"
            >
              Chamar
            </v-btn>
            <!-- <v-btn :disabled="!paciente.PetCodigo" small depressed color="success" @click="atender(paciente)">
              Atender
            </v-btn> -->
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- <v-dialog max-width="500px" persistent fullscreen v-if="dialogAtender" v-model="dialogAtender">
      <modal-atender v-if="dialogAtender"
                     :paciente="pacienteEmAtendimento"
                     @close="dialogAtender = false"
      />
      <v-card> </v-card>
    </v-dialog> -->
  </div>

</template>

<script>
import { global } from "@/config/constants";
import axios from "axios";
// import ModalAtender from "@/components/fila_atendimento/modal_atender";

export default {
  data() {
    return {
      // pacienteEmAtendimento: {},
      // dialogAtender: false,
      tokenChamefacil: "",
      pacientes: [],
    };
  },
  // components: { ModalAtender },
  beforeMount() {
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
    this.listarFilaChamefacil();
  },
  watch: {
    pacientes() {
      setTimeout(() => {
        this.listarFilaChamefacil();
      }, 15000);
    },
  },
  methods: {
    listarFilaChamefacil() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/atendimento/listarsenhasinternaspendentesV2",
          { headers }
        )
        .then((resp) => {
          // console.log(resp.data.Senha);
          this.pacientes = resp.data.Senha;
        });
    },
    chamarSenha(idChamada) {
      this.$emit("chamarSenha", idChamada);
    },
    // atender(paciente) {
    //   if (!paciente.PetCodigo) {
    //     alert("O código do pet não está vinculado a esse atendimento. Verificar no chamefacil.")
    //   }
    //   this.pacienteEmAtendimento = paciente;
    //   this.dialogAtender = true;
    // },
  },
};
</script>

<style scoped></style>
