<template>
  <div>
    <!-- LISTAGEM DE HORÁRIOS -->
    <!-- filtro -->

    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Importação</h3>
      </div>
    </div>

    <!-- DIALOG DE CADASTRO DE HORÁRIO INDIVIDUAL -->
    <v-card class="p-lg-3" elevation="0">
      <v-card-title>
        <div class="p-lg-3">
          <div class="row justify-content-between"></div>
        </div>
      </v-card-title>
      <modal-criar-importacao @response="finishHorarioIndividualCadastro" />
    </v-card>
  </div>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import ModalCriarImportacao from "@/components/importacao/modal_criar_importacao.vue";

export default {
  mixins: [VuexSnackbarMixin],
  components: {
    ModalCriarImportacao,
  },
  beforeMount() {
 
  },
  data: () => ({
    filtro: {},
    sort: "",
    sorting: {},
    horarios: [], // Guarda o array de horários que alimenta a tabela.
    horario: {}, // Guarda os dados do horário escolhido quando alguma ação for realizada.
    dialogCriacaoHorarioIndividual: false,
    dialogCriacaoFaixaHorarios: false,
    search: "", // Guarda a variável usada para fazer pesquisa na lista de horários.
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      ordenacao: false,
      desc: false,
    },
  }),
  methods: {
    selectHorarioForEdition(item) {
      this.horario = JSON.parse(JSON.stringify(item));
      this.dialogEdicaoHorario = true;
    },
    selectHorarioForDeletion(item) {
      this.horario = JSON.parse(JSON.stringify(item));
      this.dialogDelete = true;
    },

    ordenar(sorting) {
      if (sorting[0] === false) {
        console.log("desc");
        this.sort = "-";
      } else if (sorting[0] === true) {
        console.log("asc");
        this.sort = "";
      }
    },
  },
};
</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(62, 104, 42) !important;
  border-color: rgb(62, 104, 42);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>
