<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Edição de Setor</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-12 form-group clearfix mb-3">
          <label for="nome">Descrição do Setor</label>
          <v-text-field
            v-model="setorDescricao"
            placeholder="Descrição do Setor"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-3">
          <label>Centro de Custo</label>
          <v-select
            style="padding-top: 0 !important"
            :items="centroCustoList"
            v-model="centroCustoId"
          />
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="salvarEdicao"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Salvar Edição </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import SetorService from "../../services/setor_service";
import CentroCustoService from "../../services/centro_custo_service";

export default {
  mixins: [Mixin],

  data: () => ({
    singleSelect: false,
    loading: false,
    formCadastro: "",
    setorDescricao: "",
    centroCustoList: [],
    centroCustoId: 0,
    setorId: 0,
    setorService: SetorService.build(),
    centroCustoService: CentroCustoService.build(),
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  props: {
    setor: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.loading = true;
    this.formCadastro = document.forms[0];
    await this.setCentroCustoList();
    this.setorId = this.setor.id;
    this.setorDescricao = this.setor.descricao;
    this.centroCustoId = this.setor.centro_custo_id;
    this.loading = false;
  },
  methods: {
    async salvarEdicao() {
      this.loading = true;
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("descricao", this.setorDescricao);
        formData.append("id_centro_custo", this.centroCustoId);
        const response = await this.setorService.editar(this.setorId, formData);
        this.$_ACTIONS_showSnackbarMessage({
          message:
            response.status === 200
              ? "A Setor foi atualizado com sucesso."
              : this.$global.messages.internalServerError,
          color: response.status === 200 ? "sucess" : "error",
        });
        response.status === 200 && this.$emit("response", this.setor);
      } catch (e) {
        console.error({ e });
      }
      this.loading = false;
    },
    async setCentroCustoList() {
      await this.centroCustoService
        .getCentroCustoPaginatedList({
          page: null,
          per_page: null,
          sort: null,
          desc: null,
          search: null,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { centro_custo } = data;
          this.centroCustoList = centro_custo.map(({ id, descricao }) => {
            return {
              value: id,
              text: descricao,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
