<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Requisitar exames</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <v-row class="mb-3">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersProcedimentos"
          :items="procedimentos"
          class="elevation-1 col-6"
          :loading="loadingProcedimentos"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="#3E682A"
              dark
              elevation="0"
              small
              class="mb-2"
              @click="listarConvenio(item)"
            >
              Carregar Convênios
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>

        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersConvenios"
          :items="convenios"
          class="elevation-1 col-6"
          :loading="loadingConvenios"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="#3E682A"
              dark
              elevation="0"
              small
              class="mb-2"
              @click="cadastrarRequisicao(item)"
            >
              Requisitar
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-row>
      <v-row class="my-3">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersRequisicoes"
          :items="requisicoes"
          class="elevation-1 col-6"
          :loading="loadingRequisicoes"
          :loading-text="'Carregando...'"
        >
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import ConvenioService from "@/services/convenio_service.js";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin],
  data: () => ({
    procedimentos: [],
    convenios: [],
    loadingProcedimentos: false,
    loadingConvenios: false,
    headersProcedimentos: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "procedimentoDescricao",
      },
      {
        text: "Tipo Procedimento",
        align: "start",
        sortable: true,
        value: "tipoProcedimento",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headersConvenios: [
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "convenioDescricao",
      },
      {
        text: "Tipo Convênio",
        align: "start",
        sortable: true,
        value: "tipoConvenio",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    requisicoes: [],
    loadingRequisicoes: false,
    headersRequisicoes: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "procedimentoDescricao",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "convenioDescricao",
      },
      {
        text: "Tipo Convênio",
        align: "start",
        sortable: true,
        value: "tipoConvenio",
      },
    ],
    convenioService: ConvenioService.build(),
    requisicaoExameService: RequisicaoExameService.build(),
  }),
  props: {
    petId: {
      required: true,
    },
    consultaId: {
      required: true,
    },
  },
  watch: {},
  async mounted() {
    await this.setRequisicoes();
    await this.setProcedimentos();
  },
  computed: {},
  methods: {
    async setProcedimentos() {
      this.loadingProcedimentos = true;
      await this.convenioService
        .getProcedimentos()
        .then(async (response) => {
          const { data } = await response.json();
          this.procedimentos = data.procedimentos.map((item) => {
            return {
              procedimentoId: item.id,
              procedimentoDescricao: item.descricao,
              tipoProcedimento: item.tipo_procedimento,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingProcedimentos = false;
        });
    },
    async cadastrarRequisicao(convenio) {
      await this.requisicaoExameService
        .cadastrarRequisicaoExame({
          id_medico: this.$_GETTERS_usuario.dados.medicoId,
          id_convenio_procedimento: convenio.convenioProcedimentoId,
          id_consulta: this.consultaId,
        })
        .then(async (response) => {
          if (response.status !== 200) {
            this.$_SET_SNACKBAR({
              text: "Erro ao requisitar exame",
              color: "error",
            });
            await this.setRequisicoes();
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Requisição cadastrada com sucesso!",
            color: "sucess",
          });
          await this.setRequisicoes();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async setRequisicoes() {
      await this.requisicaoExameService
        .getRequisicoesAbertas(this.petId)
        .then(async (response) => {
          const { data } = await response.json();
          if (!data.length) return;
          this.requisicoes = data.map((item) => {
            return {
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
            };
          });
        });
    },
    async listarConvenio(procedimento) {
      this.loadingConvenios = true;
      await this.convenioService
        .getConvenioPorUnidadeProcedimento(
          this.$_GETTER_Unidade.id,
          procedimento.procedimentoId
        )
        .then(async (response) => {
          const { data } = await response.json();
          this.convenios = data.map((item) => {
            return {
              convenioId: item.convenio_id,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              convenioProcedimentoId: item.convenio_procedimento_id,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingConvenios = false;
        });
    },
  },
};
</script>
