<template>
  <div>
    <div v-if="pergunta.id_tipo_pergunta===1" class="justify-center">
      <form-consultas-field-tipo-text :pergunta="pergunta" @resposta="respondido($event)" />
    </div>

    <div v-if="pergunta.id_tipo_pergunta===2">
      <form-consultas-field-tipo-paragrafo :pergunta="pergunta" @resposta="respondido($event)" />
    </div>
      
    <div v-if="pergunta.id_tipo_pergunta===3">
      <form-consultas-field-tipo-radio :pergunta="pergunta" @resposta="respondido($event)" />
    </div>

    <div v-if="pergunta.id_tipo_pergunta===4">
      <form-consultas-field-tipo-check :pergunta="pergunta" @resposta="respondido($event)" />
    </div>

    <div v-if="pergunta.id_tipo_pergunta===5">
      <form-consultas-field-tipo-numeric :pergunta="pergunta" @resposta="respondido($event)" />
    </div>

    <div v-if="pergunta.id_tipo_pergunta===6">
      <form-consultas-field-tipo-text :pergunta="pergunta" @resposta="respondido($event)" />
    </div>

    <div v-if="pergunta.id_tipo_pergunta===7">
      <form-consultas-field-tipo-numeric :pergunta="pergunta" @resposta="respondido($event)" />
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FormConsultasFieldTipoText from "@/components/consultas/formulario_consulta_campo_text.vue";
import FormConsultasFieldTipoNumeric from "@/components/consultas/formulario_consulta_campo_numeric.vue";
import FormConsultasFieldTipoParagrafo from "@/components/consultas/formulario_consulta_campo_paragrafo.vue";
import FormConsultasFieldTipoRadio from "@/components/consultas/formulario_consulta_campo_radio.vue";
import FormConsultasFieldTipoCheck from "@/components/consultas/formulario_consulta_campo_check.vue";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
  },
  beforeMount(){
    console.log("this.pergunta")
    console.log(this.pergunta)
  },
  components:{
    FormConsultasFieldTipoText,
    FormConsultasFieldTipoNumeric,
    FormConsultasFieldTipoParagrafo,
    FormConsultasFieldTipoRadio,
    FormConsultasFieldTipoCheck
  },
  data: () => ({

  }),
  methods:{
    respondido(e){
      console.log("fill")
      console.log(e)
      this.$emit("resposta",e)
    }
  },
  watch: {
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>