<template>
  <div>
    <div class="navbar-custom">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <v-app-bar-nav-icon @click="menuRetratil"></v-app-bar-nav-icon>
          <v-fade-transition>
            <v-img
              v-show="hidden"
              src="@/assets/images/logo-horizontal.png"
              width="135px"
            ></v-img>
          </v-fade-transition>
        </div>
        <v-menu offset-y transition="scale-transition" origin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              style="background-color: transparent !important; height: auto"
              class="p-2"
              elevation="0"
            >
              <div
                class="d-flex"
                v-if="DADOS_CADASTRAIS_MIXIN_srcFotoUsuario !== ''"
              >
                <v-list-item-avatar>
                  <img
                    class="mr-1 round"
                    :src="DADOS_CADASTRAIS_MIXIN_srcFotoUsuario"
                    alt=""
                  />
                </v-list-item-avatar>
                <v-list-item-title>
                  <p class="mb-0">{{ saudacoes }}</p>
                  <p>{{ unidadeLogada }}</p>
                  <i></i>
                </v-list-item-title>
              </div>
              <v-progress-circular
                v-else
                indeterminate
                color="green"
              ></v-progress-circular>
            </v-btn>
          </template>
          <ul class="menu-perfil text-left mb-0">
            <li>
              <a @click="Dados_cadastrais">Dados cadastrais</a>
            </li>
            <li>
              <v-btn @click="logout">Sair</v-btn>
            </li>
          </ul>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
  mixins: [VuexUsuarioMixin, VuexTokenMixin, DadosCadastraisMixin],
  data: () => ({
    hidden: false,
  }),

  computed: {
    primeiroNomeUsuario() {
      return this.$_GETTERS_usuario.dados
        ? this.$_GETTERS_usuario.dados.nome.split(" ")[0]
        : "";
    },
    saudacoes() {
      if (this.primeiroNomeUsuario) {
        return `Olá, ${this.primeiroNomeUsuario}, ${this.$_GETTER_perfil.descricao}`;
      }
      return "";
    },
    unidadeLogada() {
      return this.$_GETTER_Unidade.nome;
    },
    perfilDescricao() {
      return this.$_GETTER_perfil.descricao
        ? this.$_GETTER_perfil.descricao
        : "";
    },
  },

  methods: {
    Dados_cadastrais() {
      this.$router.replace("dadoscadastrais");
    },
    Trocar_senha() {
      this.$router.replace("trocarsenha");
    },
    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setUnidade({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");

      localStorage.removeItem("idFilaAlocada");
      localStorage.removeItem("dadosUsuario");
    },
    menuRetratil() {
      this.hidden = !this.hidden;
      this.$emit("retratil");
    },
  },
};
</script>

<style scoped>
.navbar-custom {
  /* padding: 20px 40px; */
  background-color: #fff;
  -webkit-box-shadow: 0 0 45px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 0 45px 0 rgb(0 0 0 / 12%);
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  position: fixed;
}

.menu-perfil {
  list-style: none;
  padding: inherit;
  background-color: #fff;
}

.menu-perfil li {
  padding: 0.5rem 0 0.5rem 1rem;
}

.menu-perfil li a {
  font-size: 1rem;
}
</style>
