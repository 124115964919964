import Home from "@/views/home.vue";
import Login from "@/views/login.vue";
import EsqueceuSenha from "@/views/esqueceu-senha.vue";
import CadastroPaciente from "@/views/cadastro_paciente.vue";
import Pacientes from "@/views/pacientes.vue";
import Perfis from "@/views/perfis.vue";
import Unidades from "@/views/unidades.vue";
import Assistentes from "@/views/assistentes.vue";
import Atendentes from "@/views/atendentes-recepcao.vue";
import Administrativos from "@/views/administrativos.vue";
import Agendamento from "@/views/agendamentos.vue";
import Horarios from "@/views/horarios.vue";
import Procedimentos from "@/views/procedimentos";
import Racas from "@/views/racas";
import TipoProcedimento from "@/views/tipo_procedimentos";
import Dashboard from "@/views/dashboard.vue";
import Recepcao from "@/views/recepcao.vue";
import DadosCadastrais from "@/views/dados_cadastrais.vue";
import TrocarSenha from "@/views/trocar_senha.vue";
import Prontuarios from "@/views/prontuarios.vue";
import MeuProntuario from "@/views/prontuario_paciente.vue";
import Formulario from "@/views/formularios.vue";
import Exames from "@/views/exames.vue";
import Medicamentos from "@/views/medicamentos.vue";
import Importacao from "@/views/importacao.vue";
import endpoints from "./endpoints";
import Pets from "@/views/pets.vue";
import Especies from "@/views/especies.vue";
import CentroCusto from "@/views/centro_custo.vue";
import FormaPagamento from "@/views/forma_pagamento.vue";
import Setor from "@/views/setor.vue";
import Medicos from "@/views/medicos.vue";
import AgendarConsulta from "@/views/agendar_consulta.vue";
import MinhasConsultas from "@/views/minhas_consultas.vue";
// import ClassificacaoRisco from "@/views/classificacao_risco.vue";
import Convenios from "@/views/convenios.vue";
import RecepcaoMedico from "@/views/recepcao_medico.vue";
import FilaAtendimento from "@/views/fila_atendimentos.vue";
import MinhasAgendas from "@/views/minhas_agendas.vue";

// AS

const routes = [
  {
    path: "/home",
    name: "home",
    component: Home,
    icon: "dripicons-calendar",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/principal",
    name: "dashboard",
    component: Dashboard,
    icon: "dripicons-checklist",
    label: "Início",
    menu: true,
    endpoints: [
      endpoints["DashboardController::GET->getDashboardByPacienteID/$1"],
      endpoints["DashboardController::GET->getDashboardByPacienteID/$1"],
    ],
  },
  // {
  //   path: "/atendentes",
  //   name: "atendentes",
  //   component: Atendentes,
  //   icon: "dripicons-user-group",
  //   label: "Atendentes - Recepção",
  //   menu: true,
  //   endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  // },
  {
    path: "/recepcao",
    name: "recepcao",
    component: Recepcao,
    icon: "dripicons-checklist",
    label: "Recepcao",
    menu: true,
    endpoints: [endpoints["RecepcaoController::GET->getRecepcionistaList"]],
  },
  {
    path: "/fila-atendimento",
    name: "fila_atendimento",
    component: FilaAtendimento,
    icon: "dripicons-checklist",
    label: "Fila de Atendimento",
    menu: true,
    endpoints: [
      endpoints["DashboardController::GET->getDashboardByPacienteID/$1"],
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    icon: "dripicons-calendar",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/esqueceu-senha",
    name: "esqueci-minha-senha",
    component: EsqueceuSenha,
    icon: "",
    label: "",
    menu: false,
    endpoints: [],
  },
  {
    path: "/novo-cadastro",
    name: "cadastro-paciente",
    component: CadastroPaciente,
    icon: "dripicons-user",
    label: "Cadastro de Tutores",
    menu: false,
    endpoints: [],
  },
  {
    path: "/agendar_consulta",
    name: "agendar_consulta",
    component: AgendarConsulta,
    icon: "dripicons-user",
    label: "Agendar Consulta",
    menu: true,
    perfis: [4], // paciente
    endpoints: [],
  },
  {
    path: "/minhas-agendas",
    name: "minhas_agendas",
    component: MinhasAgendas,
    icon: "dripicons-user",
    label: "Minhas Agendas",
    menu: true,
    endpoints: [endpoints["PacienteController::GET->getPacienteList"]],
  },
  {
    path: "/minhas-consultas",
    name: "minhas_consultas",
    component: MinhasConsultas,
    icon: "dripicons-user",
    label: "Minhas Consultas",
    menu: true,
    endpoints: [],
  },
  {
    path: "/tutores",
    name: "tutores",
    component: Pacientes,
    icon: "dripicons-user",
    label: "Tutores",
    menu: true,
    endpoints: [endpoints["PacienteController::GET->getPacienteList"]],
  },
  {
    path: "/unidades",
    name: "unidades",
    component: Unidades,
    icon: "dripicons-user",
    label: "Unidades",
    menu: true,
    endpoints: [endpoints["UnidadeController::GET->getUnidadeList"]],
  },
  {
    path: "/centro-custo",
    name: "centro_custo",
    component: CentroCusto,
    icon: "dripicons-user",
    label: "Centro de Custo",
    menu: true,
    endpoints: [endpoints["CentroCustoController::GET->getCentroCustoList"]],
  },
  {
    path: "/forma-pagamento",
    name: "forma_pagamento",
    component: FormaPagamento,
    icon: "dripicons-user",
    label: "Formas de Pagamento",
    menu: true,
    endpoints: [
      endpoints["FormaPagamentoController::GET->getFormaPagamentoList"],
    ],
  },
  {
    path: "/setor",
    name: "setor",
    component: Setor,
    icon: "dripicons-user",
    label: "Setores",
    menu: true,
    endpoints: [endpoints["SetorController::GET->getSetorList"]],
  },
  {
    path: "/convenios",
    name: "convenios",
    component: Convenios,
    icon: "dripicons-user",
    label: "Convênios",
    menu: true,
    endpoints: [endpoints["ConvenioController::GET->getConvenioList"]],
  },
  {
    path: "/administrativos",
    name: "administrativos",
    component: Administrativos,
    icon: "dripicons-user-group",
    label: "Administrativos",
    menu: true,
    endpoints: [
      endpoints["AdministrativoController::GET->getAdministrativoList"],
    ],
  },
  {
    path: "/assistentes",
    name: "assistentes",
    component: Assistentes,
    icon: "dripicons-user-group",
    label: "Assistentes",
    menu: true,
    endpoints: [endpoints["AssistenteController::GET->getAssistenteList"]],
  },
  {
    path: "/atendentes",
    name: "atendentes",
    component: Atendentes,
    icon: "dripicons-user-group",
    label: "Atendentes - Recepção",
    menu: true,
    endpoints: [endpoints["AssistenteController::GET->getAssistenteList"]],
  },
  {
    path: "/prontuarios",
    name: "prontuarios",
    component: Prontuarios,
    icon: "fas fa-notes-medical",
    label: "Prontuários",
    menu: true,
    endpoints: [
      endpoints["ConsultaController::GET->getConsultaPacienteByMedicoID/$1"],
    ],
    props: { search: false },
  },
  {
    path: "/agendamentos",
    name: "agendamentos",
    component: Agendamento,
    icon: "dripicons-calendar",
    label: "Agendamentos",
    menu: true,
    endpoints: [endpoints["AgendaController::GET->getAgenda"]],
  },
  {
    path: "/horarios",
    name: "horarios",
    component: Horarios,
    icon: "dripicons-stopwatch",
    label: "Horários",
    menu: true,
    endpoints: [endpoints["AgendaController::GET->getAgenda"]],
  },
  {
    path: "/formularios",
    name: "formularios",
    component: Formulario,
    icon: "fas fa-file-medical-alt",
    label: "Formulários",
    menu: true,
    endpoints: [
      endpoints["FormularioController::GET->getFormulariosByMedicoID/$1"],
    ],
  },
  {
    path: "/procedimentos",
    name: "procedimentos",
    component: Procedimentos,
    icon: "dripicons-medical",
    label: "Procedimentos",
    menu: true,
    endpoints: [endpoints["ProcedimentoController::GET->getProcedimentoList"]],
  },
  {
    path: "/tipos-procedimentos",
    name: "tipos-procedimentos",
    component: TipoProcedimento,
    icon: "dripicons-medical",
    label: "Tipos de Procedimentos",
    menu: true,
    endpoints: [
      endpoints["TipoProcedimentoController::GET->getTipoProcedimentoList"],
    ],
  },
  // {
  //   path: "/classificacao-risco",
  //   name: "classificacao-risco",
  //   component: ClassificacaoRisco,
  //   icon: "dripicons-medical",
  //   label: "Classificação de Risco",
  //   menu: true,
  //   endpoints: [
  //     endpoints["TipoProcedimentoController::GET->getTipoProcedimentoList"],
  //   ],
  // },
  {
    path: "/exames",
    name: "exames",
    component: Exames,
    icon: "fas fa-vial",
    label: "Exames",
    menu: true,
    endpoints: [endpoints["ExameController::GET->getExameList"]],
  },
  {
    path: "/perfis",
    name: "perfis",
    component: Perfis,
    icon: "fas fa-vial",
    label: "Perfis",
    menu: true,
    endpoints: [endpoints["PerfilController::GET->getPerfilList"]],
  },
  {
    path: "/medicamentos",
    name: "medicamentos",
    component: Medicamentos,
    icon: "fas fa-medkit",
    label: "Medicamentos",
    menu: true,
    endpoints: [endpoints["MedicamentoController::GET->getMedicamentoList"]],
  },
  {
    path: "/dadoscadastrais",
    name: "dadoscadastrais",
    component: DadosCadastrais,
    icon: "",
    label: "Dados Cadastrais",
    menu: false,
    endpoints: [],
  },
  {
    path: "/trocarsenha",
    name: "trocarsenha",
    component: TrocarSenha,
    icon: "",
    label: "Trocar senha",
    menu: false,
    endpoints: [],
  },
  {
    path: "/filas-atendimento-medico",
    name: "filasatendimentomedico",
    component: RecepcaoMedico,
    icon: "dripicons-medical",
    label: "Filas de atendimento",
    menu: true,
    endpoints: [],
  },
  {
    path: "/meu-prontuario",
    name: "meu-prontuario",
    component: MeuProntuario,
    icon: "fas fa-file-medical-alt",
    label: "Meu prontuário",
    menu: true,
    endpoints: [[endpoints["PacienteController::GET->getMeuProntuario/$1"]]],
  },
  {
    path: "/medicos",
    name: "medicos",
    component: Medicos,
    icon: "fas fa-file-medical-alt",
    label: "Médicos",
    menu: true,
    endpoints: [endpoints["MedicoController::GET->getMedicoList"]],
  },
  {
    path: "/pets",
    name: "pets",
    component: Pets,
    icon: "fas fa-file-medical-alt",
    label: "Pets",
    menu: true,
    endpoints: [endpoints["PetController::GET->getPetList"]],
  },
  {
    path: "/especies",
    name: "especies",
    component: Especies,
    icon: "fas fa-file-medical-alt",
    label: "Especies",
    menu: true,
    endpoints: [
      endpoints["EspecieController::GET->getEspecieList"],
      endpoints["EspecieController::GET->findAll"],
    ],
  },
  {
    path: "/racas",
    name: "racas",
    component: Racas,
    icon: "fas fa-file-medical-alt",
    label: "Raças",
    menu: true,
    endpoints: [endpoints["RacaController::GET->getRacaList"]],
  },
  {
    path: "/importacao",
    name: "importacao",
    component: Importacao,
    icon: "fas fa-file",
    label: "Importaçao",
    menu: true,
    endpoints: [
      endpoints["TipoProcedimentoController::GET->getTipoProcedimentoList3"],
    ],
  },
];

export default routes;
