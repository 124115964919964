<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title>
        <span class="headline">Marcar agendamento</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="row">
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          @click="$emit('close')"
        >
          Fechar
        </v-btn>
        <v-btn
          style="background-color: #3e682a; color: #fff !important; margin-left:auto;"
          text
          @click="agendar"
        >                                            
          <span v-if="!loadingAgendamento">
              Agendar
          </span>    
          <v-progress-circular
              v-else
              indeterminate
              color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>

import AgendaService from "@/services/agenda_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  props: {
    detalhesAgenda: {
      type: Object,
      validator: obj => {
        return obj ? !!obj.idAgenda : false
      }
    }
  },
  mounted() {},
  data: () => ({
    colorSnackbar: '',
    loading: false,
    loadingAgendamento: false,
    procedimento:'',
  }),
  methods: {
    agendar() {
        const idAgenda = this.detalhesAgenda.idAgenda
        const paciente = this.$_GETTERS_usuario
        const request = {
          'id_paciente': paciente.idPaciente,
          'id_cliente': paciente.idCliente,
          'alterado_por': paciente.id,
          'adicionado_por': paciente.id,
          'id_agenda': idAgenda
        }
        const agendaService = new AgendaService()
        this.loadingAgendamento = true
        agendaService.agendar_paciente({
          onSucess: status => body => {
            request.id_agendamento = body.data.id_agendamento
            if (status === 200) {
              this.$emit('submit', {
                status,
                message: body.message,
                request
              })
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: 'error'
              })
            }
          },
          onError: error => {
            this.$emit('submit', { error })
          },
          onEnd: () => {
            this.loadingAgendamento = false
          }
        }, request )
    },
  },
};
</script>