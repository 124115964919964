import HTTPService from "@/services/http_service.js";
// import { global } from "@/config/constants";

export default class RequisicaoExameService extends HTTPService {
  resource = "requisicao";

  static build() {
    return new RequisicaoExameService();
  }

  async getRequisicoesAbertas(petId) {
    return await this.get(`${this.resource}/pet/${petId}`);
  }

  cadastrarRequisicaoExame(requisicao) {
    return this.post(this.resource, { ...requisicao });
  }

  marcarExecutado(requisicaoId) {
    requisicaoId = ~~requisicaoId;
    return this.post(`requisicao/marcar-executado/${requisicaoId}`);
  }
}
