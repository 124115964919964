<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Atender </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <h3>Requisições em aberto</h3>
      <v-data-table
        v-on:update:sort-by="options.ordenacao = true"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :headers="headersRequisicoes"
        :items="requisicoes"
        class="elevation-1"
        :loading="loadingRequisicoes"
        :loading-text="'Carregando...'"
      >
        <template v-slot:no-data>
          <p class="text-center">Nenhum registro encontrado</p>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            :loading="loadingRequisicaoId == item.requisicaoId"
            @click="marcarExecutado(item)"
            >Marcar executado</v-btn
          >
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";

export default {
  mixins: [Mixin],
  components: {},
  data: () => ({
    loadingRequisicoes: false,
    headersRequisicoes: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "procedimentoDescricao",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "convenioDescricao",
      },
      {
        text: "Tipo Convênio",
        align: "start",
        sortable: true,
        value: "tipoConvenio",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    requisicoes: [],
    requisicaoExameService: RequisicaoExameService.build(),
    loadingRequisicaoId: 0,
  }),
  props: {
    petCodigo: {
      type: Number,
      required: true,
    },
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.setRequisicoes();
  },
  methods: {
    async setRequisicoes() {
      await this.requisicaoExameService
        .getRequisicoesAbertas(this.petCodigo)
        .then(async (response) => {
          const { data } = await response.json();
          if (!data.length) {
            this.requisicoes = [];
            return;
          }
          this.requisicoes = data.map((item) => {
            return {
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
            };
          });
        })
        .catch((e) => {
          console.error({ e });
          this.requisicoes = [];
        });
    },
    async marcarExecutado(requisicao) {
      this.loadingRequisicaoId = requisicao.requisicaoId;
      await RequisicaoExameService.build().marcarExecutado(
        ~~requisicao.requisicaoId
      );
      await this.setRequisicoes();
      this.loadingRequisicaoId = 0;
    },
  },
};
</script>
