<template>
  <div>
    <v-card id="ModalIniciarConsulta" min-height="100vh">
      <v-toolbar dark color="green darken-4">
        <v-btn icon dark @click="fecharModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Consulta</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="salvarConsulta"> Salvar </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container v-if="loadingFormulario">
        <v-progress-circular indeterminate color="green"></v-progress-circular>
      </v-container>
      <div v-else class="p-4">
        <div class="row">
          <div class="col-lg-10">
            <h2 class="NomePacienteAtual text-left">
              Paciente: {{ paciente.nome }}
            </h2>
          </div>
          <div class="col-lg-2">
            <div class="d-flex justify-content-between align-items-center">
              <label class="mr-2">Data:</label>
              <v-text-field
                clear-icon="clear"
                style="margin: 0 5px; padding-top: 5px !important"
                :type="'date'"
                :outlined="false"
                v-model="DataHoje"
              />
            </div>
          </div>
          <div v-if="false" class="d-flex flex-column col-12">
            <label for=""><strong>Título</strong></label>
            <v-text-field
              v-model="form_resp.titulo"
              placeholder="Ex: Consulta Básica"
              required
              class="col-6 m-auto"
            ></v-text-field>
          </div>
          <div v-if="false" class="d-flex flex-column col-12">
            <label for=""><strong>Observação</strong></label>
            <v-text-field
              v-model="form_resp.descricao"
              placeholder="Ex: Formulário para uma primeira consulta"
              required
              class="col-6 m-auto"
            ></v-text-field>
          </div>
          <div
            class="col-lg-12"
            v-for="(input, index) in form.perguntas"
            :key="`pergunta${index}`"
          >
            <div class="col-12 d-flex justify-center">
              <form-consulta-fill
                :pergunta="input"
                v-if="input"
                @resposta="respondido($event)"
                class="col-10"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold"
              @click="fecharModal"
            >
              Cancelar
            </button>
            <button
              class="btn btn-app-primary btn-padding font-weight-bold"
              @click="salvarConsulta"
            >
              <span v-if="!loading"> Salvar</span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </button>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import FormularioService from "@/services/formulario_service.js";
import ConsultaService from "@/services/consulta_service.js";
import FormConsultaFill from "@/components/consultas/formulario_consulta_fill.vue";
import Mixin from "@/mixins/vuex_mixin.js";


export default {
  mixins:[
    Mixin
  ],
  props: {
    paciente: Object,
    info: Object,
  },
  components: {
    FormConsultaFill,
  },
  beforeMount() {
    this.form_resp.id_agendamento = this.info.idAgendamento
    this.form_resp.id_formulario = this.info.idFormulario
    this.loadingForm();
  },
  data: () => ({
    //Iniciar consulta
    dialog: false,
    loading: false,
    loaded: false,
    loadingFormulario: false,
    form: {},
    resp:[],
    form_resp: {
      id_formulario:0,
      id_agendamento:0,
      titulo:"",
      descricao:"",
      respostas: [],
    },
    //Dados form
    DataHoje: new Date().toISOString().substr(0, 10),

    //Checkboxes forms opcionais
    checkboxAnamnese: false,
    checkboxGestacao: false,
    checkboxExamefisico: false,
    checkboxCirugiaPrevista: false,
    checkboxMateriaisEspeciais: false,
  }),
  watch: {
    loaded() {
      if (this.loadingForm && this.form.perguntas) {
        this.loaded = true;
      }
    },
  },
  methods: {
    respondido(e) {
      if (this.resp.length<1) {
        this.resp.push({id_pergunta:e.id_pergunta, resposta:e.resposta})
      }else{
        let respEvent = this.resp.map(p => p.id_pergunta).indexOf(e.id_pergunta)
        if (respEvent>=0) {
          this.resp[respEvent] = {id_pergunta:e.id_pergunta, resposta:e.resposta}
        }else{
          this.resp.push({id_pergunta:e.id_pergunta, resposta:e.resposta})
        }
      }
    },
    check: function (e) {
      e.cancelBubble = true;
      console.log("checkbox checked");
    },
    expansionPanel: function () {
      console.log("expansion panel");
    },

    fecharModal() {

      this.$emit("close");
    },

    salvarConsulta() {
      if (this.form_resp.titulo =="" ) {
        // this.$_ACTIONS_showSnackbarMessage({
        //   message:"O campo Título é obrigatorio",
        //   color: "error",
        // });
        // return;
        this.form_resp.titulo = "titulo"
      }
      if (this.form_resp.descricao =="") {
        // this.$_ACTIONS_showSnackbarMessage({
        //   message: "O campo Descrição é obrigatorio",
        //     color: "error",
        //   });
        // return;
        this.form_resp.descricao = "descricao"
      }     
      this.form_resp.id_formulario = this.info.idFormulario
      this.form_resp.respostas = this.resp
      const consultaService = new ConsultaService();
      consultaService.register({
         onSucess: (status) => (body) => {
            if (status === 200) {
               this.form_resp = {}
               this.resp = []
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Consulta salva com sucesso",
                color: "sucess",
              });
              this.$emit("close");
              this.$emit("reloadConsultas");
              console.log({body});
              this.$emit("finalizarConsulta", { msg:body.message, cor:"sucess", nome: this.paciente.nome, consultaId: ~~body.data.consulta_id} );
            }else{
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loadingNovoFormulario = false;
          },
        },
        this.form_resp
        );
      
    },

    loadingForm() {
      this.loadingFormulario = true;
      const formularioService = new FormularioService();
      this.form = {}
      formularioService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.form.titulo = body.data.titulo;
              this.form.descricao = body.data.descricao;
              this.form.id_medico = body.data.id_medico;
              this.form.perguntas = body.data.perguntas;
              this.loadingFormulario = false;
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            // this.$emit("close", { error });
            console.log(error);
          },
          onEnd: () => {},
        },
        this.info.idFormulario
      );
    },
  },
};
</script>

<style scoped>
.btn-cancelar {
  color: #6c757d;
}

.btn-padding {
  padding-left: 2rem;
  padding-right: 2rem;
}
</style>

<style>
#ModalIniciarConsulta .v-input--is-disabled,
#ModalIniciarConsulta .v-input--is-disabled:hover,
#ModalIniciarConsulta .v-input--is-readonly,
#ModalIniciarConsulta .v-input--is-readonly:hover {
  pointer-events: initial !important;
}

#ModalIniciarConsulta .v-input--is-disabled textarea:hover,
#ModalIniciarConsulta .v-input--is-disabled input:hover,
#ModalIniciarConsulta .v-input--is-readonly input:hover {
  cursor: no-drop !important;
}

.checkbox-no-rotate .v-icon {
  transform: rotate(0) !important;
}
</style>