import HTTPService from '@/services/http_service.js'

export default class ProcedimentoService extends HTTPService {
    resource = 'procedimento'

    static build() {
        return new ProcedimentoService();
    }

    cadastrar (responseFunctions, procedimento) {
        this.request(responseFunctions, 'POST', this.resource, procedimento)
    }

    getProcedimentos (responseFunctions, descricao) {
        let imprevisto = descricao ?? ''
        if (imprevisto === '') {
            this.request(responseFunctions, 'GET', this.resource, null)
        } else {
            this.request(responseFunctions, 'GET', this.resource, null, descricao)
        }
    }

    async findAll() {
        try {
            const response = await this.get('procedimento')
            if (response.status !== 200) return [];
            const {data} = await response.json();
            return data.procedimentos.map(p => ({
                id: p.id,
                descricao: p.descricao,
                tipoProcedimentoId: p.id_tipo_procedimento,
                tipoProcedimento: p.tipo_procedimento,
            }));
        } catch (e) {
            return [];
        }
    }

    getList (responseFunctions) {
      this.request(responseFunctions, 'GET', this.resource,null)
    }

    getAll (onSucess, onError, onEnd, page, per_page, filter, order, desc) {
      const responseFunctions = {
          onSucess,
          onError,
          onEnd
      }
      let queryparams ={
        page: page,
        per_page: per_page
      }
      if(filter ===''){
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            sort: desc?'descricao':'-descricao'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }
      }else{
        let queryparams ={
          page: page,
          per_page: per_page,
          descricao: filter,
        }
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            descricao: filter,
            sort: desc?'descricao':'-descricao'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource, null, queryparams)
        }
      }
    }

    deleteProcedimento ({ onSucess, onError, onEnd }, { id }) {
      // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR. 
        const uri = `${this.resource}/${id}/deletar`
        this.request({ onSucess, onError, onEnd }, 'POST', uri)
    }

    updateDescricaoProcedimento ({ onSucess, onError, onEnd }, procedimento) {
        const uri = `${this.resource}/${procedimento.id}`
        this.request({ onSucess, onError, onEnd }, 'POST', uri, procedimento)
    }

    searchByDescricao (vm) {
        // Para usar esse método, o componente deve ter como atributos em data
        // "procedimentos" (array) e "loading"(boolean)
        return descricao => {
            if (!descricao) {
                vm.procedimentos = [];
                vm.procedimento='';
              }
              if (
                !descricao ||
                !(descricao = descricao.trim()) ||
                descricao.length < 3 ||
                vm.loading_procedimento
              ) {
                return;
              }
              vm.loading_procedimento = true;
              vm.procedimentos = [];
              let queryParam = {
                descricao: descricao,
                tipo_procedimento: vm.detalhesAgenda.idTipoProcedimento
              }
            //   const procedimentoService = new ProcedimentoService();
              const requestFunctions = {
                onSucess: (status) => (body) => {
                  if (status === 200) {
                    vm.procedimentos = body.data.procedimentos;
                  } else {
                    vm.procedimentos = [];
                  }
                },
                onError: (error) => {
                  console.error(error);
                  vm.procedimentos = [];
                },
                onEnd: () => {
                  vm.loading_procedimento = false;
                },
              };
              this.getProcedimentos(requestFunctions, queryParam );
        }
    }
} 