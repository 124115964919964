<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Medicamentos</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :search="search"
      :headers="headers"
      :items="medicamentos"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="nome"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="allowCadastrarBtn" color="#3E682A" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo Medicamento
              </v-btn>
            </template>
            <modal-cadastro v-if="dialogCadastrar"  @close="closeDialogCadastrar" @response="atualizarMedicamentoPosCadastro($event)"/>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details/>
          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal;">Tem certeza de que deseja excluir este
                Medicamento?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3E682A" text @click="closeDelete">Cancelar</v-btn>
                <v-btn class="btn-danger" text style="color: #fff !important;" @click="deleteItemConfirm">Deletar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- DIALOG EDIÇÃO -->
          <v-dialog v-if="dialogEdicao" v-model="dialogEdicao" max-width="500px">
            <modal-edicao
                v-if="dialogEdicao"
                :medicamento="editedMedicamento"
                @close="dialogEdicao = false"
                @response="atualizarMedicamentoPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="allowEditarBtn" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon v-if="allowDeletarBtn" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarMedicamentos">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import MedicamentoService from '@/services/medicamento_service.js'
import modalCadastro from '@/components/medicamentos/modal_cadastro'
import modalEdicao from "@/components/medicamentos/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";

export default {
  mixins:[Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedMedicamento: {},
    search: '',
    totalList:10,
    options:{
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc:false,
    },
    headers: [
      {
        text: 'Nome',
        align: 'start',
        sortable: true,
        value: 'nome',
      },
      {text: 'Ações', align: 'end', value: 'actions', sortable: false},
    ],
    medicamentos: [],
    loading: false,
    services: {
      authService: AuthService.build()
    }
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
     options:{
      handler (){
        this.options.desc = this.options.sortDesc[0]??false
        this.atualizarMedicamentos();
      },
      deep:true
    },
    search(){
      this.atualizarMedicamentos();
    }
  },

  mounted() {
    this.atualizarMedicamentos()
  },
  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(endpoints["MedicamentoController::POST->registerMedicamento"])
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(endpoints["MedicamentoController::POST->updateMedicamento/$1"])
    },
    allowDeletarBtn() {
      return this.services.authService.temPermissao(endpoints["MedicamentoController::POST->deleteMedicamento/$1"])
    },
  },
  methods: {
    atualizarMedicamentoPosCadastro() {
      this.atualizarMedicamentos()
      this.closeDialogCadastrar()
    },
    atualizarMedicamentoPosEdicao() {
      this.dialogEdicao = false
      this.atualizarMedicamentos()
    },
    atualizarMedicamentos() {
      this.loading = true
      const medicamentoService = new MedicamentoService()
      this.loading = true;
      this.medicamentos=[];
      const onSucess = (status) => (body) => {
        if (status === 200) {
         this.totalList = body.data.pagination.num_rows
         this.medicamentos = body.data.medicamentos.map((medicamento) => {
            return medicamento;
          });
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      medicamentoService.getAll(onSucess, onError, onEnd,this.options.page, this.options.itemsPerPage, this.search, this.options.ordenacao, this.options.desc);
    },
    editItem(item) {
      this.editedMedicamento = JSON.parse(
          JSON.stringify(item)
      )
      this.dialogEdicao = true
    },

    deleteItem(item) {
      this.editedMedicamento = JSON.parse(JSON.stringify(item))
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.closeDelete()
      for (let i = 0, len = this.medicamentos.length; i < len; i++) {
        if (this.medicamentos[i].id === this.editedMedicamento.id) {
          const medicamentoService = new MedicamentoService()
          medicamentoService.deleteMedicamento({
            onSucess: status => body => {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || this.$global.messages.internalServerError,
                color: status === 200 ? 'sucess' : 'error'
              })
              status === 200 && this.medicamentos.splice(i, 1)
            },
            onError: () => {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: 'error'
              })
            },
            onEnd: () => {
            }
          }, this.editedMedicamento)
          break;
        }
      }
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
