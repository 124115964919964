<template>
    <v-data-table
        :headers="headers"
        :items="horariosAgendados"
        item-key="id"
        class="elevation-1"
        :loading="loading"
    >
    <template v-slot:no-data>
        <p class="text-center">Nenhum agendamento encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarProcedimentos">
            Atualizar
        </v-btn>
    </template>
    </v-data-table>
</template>

<script>

export default {
    props: {
        horariosAgendados: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        headers: [
            {
                text: "Tipo de procedimento",
                align: "start",
                sortable: true,
                value: "tipo_procedimento",
            },
            {
                text: "Procedimento",
                align: "start",
                sortable: true,
                value: "procedimento",
            },
            {
                text: "Hora início",
                align: "start",
                sortable: true,
                value: "hora_inicio",
            },
            
            {
                text: "Hora final",
                align: "start",
                sortable: true,
                value: "hora_final",
            },
            {
                text: "Data",
                align: "start",
                sortable: true,
                value: "data",
            },
        ],
    }),
    methods:{
        atualizarProcedimentos(){
            this.$emit("reload")
        }
    }
}
</script>
