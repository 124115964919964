import HTTPService from "@/services/http_service.js";

export default class AgendaService extends HTTPService {
  resource = "agenda";

  static build() {
    return new AgendaService();
  }

  getAgendaMedico(
    responseFunctions,
    idMedico,
    paginationComponent,
    filtro = {}
  ) {
    const pagination = paginationComponent
      ? {
          page: paginationComponent.page,
          per_page: paginationComponent.itemsPerPage,
        }
      : {};
    const id_unidade = ~~JSON.parse(sessionStorage.vuex).unidade?.id;
    const queryParams = { ...pagination, ...filtro, id_unidade };
    if (idMedico) {
      queryParams.id_medico = idMedico;
    }
    this.request(responseFunctions, "GET", this.resource, null, queryParams);
  }
  getAgendaList(responseFunctions, idMedico, options) {
    if (idMedico) {
      options.id_medico = idMedico;
    }
    options.id_unidade = ~~JSON.parse(sessionStorage.vuex).unidade?.id;
    this.request(responseFunctions, "GET", this.resource, null, options);
  }

  agendar(responseFunctions, request) {
    const uri = "agendamento";
    this.request(responseFunctions, "POST", uri, request);
  }

  agendar_paciente(responseFunctions, request) {
    const uri = "agendamento/cliente";
    this.request(responseFunctions, "POST", uri, request);
  }

  criarHorario(responseFunctions, req) {
    this.request(responseFunctions, "POST", this.resource, req);
  }

  criarAgenda(responseFunctions, req) {
    this.request(responseFunctions, "POST", this.resource, req);
  }

  criarAgendaMultiplo(responseFunctions, params) {
    this.request(
      responseFunctions,
      "POST",
      this.resource + "/multiplos",
      params
    );
  }

  atualizarTipoProcedimentoDeAgenda(
    responseFunctions,
    idTipoProcedimento,
    agendaID
  ) {
    const url = `${this.resource}/${agendaID}`;
    this.request(responseFunctions, "POST", url, {
      id_tipo_procedimento: idTipoProcedimento,
      alterado_por: 1,
    });
  }

  excluirAgenda(responseFunctions, agendaID) {
    // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR.
    const url = `${this.resource}/${agendaID}/deletar`;
    this.request(responseFunctions, "POST", url);
  }

  getAgendamentoByDia(data, unidadeId) {
    return this.get(`agenda/resumo/unidade/${unidadeId}/data/${data}`);
  }
}
