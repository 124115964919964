<template>
  <div class="info-container d-flex justify-content-between w-50 ml-5">
    <div class="text-left mr-2">
      <v-card-text>
        <span>
          <strong>Tutor: </strong>
          {{ infoPaciente.UsuarioSenha }} </span
        ><br />
        <span>
          <strong>Classificação: </strong>
          {{ infoPaciente.ClassificacaoAtendimento }} </span
        ><br />
        <span>
          <strong>Encaminhar: </strong>
          {{ infoPaciente.EncaminharPara }} </span
        ><br />
        <span>
          <strong>Procedência: </strong>
          {{ infoPaciente.Procedencia }} </span
        ><br />
        <span>
          <strong>Queixa Principal: </strong>
          {{ infoPaciente.QueixaPrincipal }} </span
        ><br />
        <span>
          <strong>Observações: </strong>
          {{ infoPaciente.Observacao }} </span
        ><br />
      </v-card-text>
    </div>

<!--    <div class="d-flex flex-column justify-content-center ">-->
<!--      <ConsultarTutor />-->
<!--      <CadastrarTutor />-->
<!--      <CadastrarPet />-->
<!--    </div>-->
  </div>
</template>

<script>
// import ConsultarTutor from "@/components/recepcao/infoChamada/buttons/consultar-cadastro/consultaCadastro.vue";
// import CadastrarPet from "@/components/recepcao/infoChamada/buttons/cadastraPet.vue";
// import CadastrarTutor from "@/components/recepcao/infoChamada/buttons/cadastrar-tutor/cadastraTutor.vue";

export default {
  components: {
    // ConsultarTutor,
    // CadastrarPet,
    // CadastrarTutor,
  },
  data() {
    return {
      infoPaciente: {},
    };
  },
  beforeMount() {
    this.infoPaciente = JSON.parse(localStorage.getItem("infoPaciente"));
    console.log(this.infoPaciente);
  },
};
</script>

<style scoped>
@media (max-width: 1080px) {
  .info-container {
    width: 80vw !important;
    margin: 0 !important;
  }
}
</style>
