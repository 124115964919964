<template>
  <div class="d-flex flex-wrap col-12">
    <div class="d-flex flex-colunm col-12">
     <label>{{ pergunta.texto_pergunta }}</label>
    </div>
    <div class="d-flex flex-wrap col-12">
      <v-radio-group
        v-model="resposta.resposta[0]"
        >
          <v-radio
            row
            v-for="n in pergunta.opcoes"
            :key="n.id"
            @change="respondido(n)"
            :label="n.descricao"
            :disabled="!disabled"
            :value="n.id"
          ></v-radio>
        </v-radio-group>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
    disabled:Boolean
  },
 beforeMount(){
     this.resposta = {
       id_pergunta:this.pergunta.id,
      resposta:[+this.pergunta.resposta_pergunta_opcao[0].resposta_opcao]
    }
  },
  data: () => ({
    resposta:{},
  }),
  methods:{
    respondido(opt){
      this.resposta.resposta[0] = +opt.id
      this.$emit("resposta", this.resposta)
      this.watchChange()
    },

    watchChange() {
      this.$emit('rchange', this.resposta.resposta.map(r => ({
         "resposta_opcao": r
      })))
    },
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>