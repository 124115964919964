<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Comanda Itens</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="comandasItens"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:item.actions="{ item }" class="d-flex row">
        <v-col class="d-flex" cols="12" sm="9">
          <v-select
            :items="formasdePagamento"
            label="Forma de pagamento"
            dense
            v-model="formaPagamentoSelecionada[item.comanda_item_id]"
            @input="selecionarFormaPagamento($event, item.comanda_item_id)"
            item-value="id"
          ></v-select>

          <v-btn
            :disabled="!formaPagamentoSelecionada[item.comanda_item_id]"
            small
            color="green"
            elevation="0"
            class="text-white ml-4 mt-1"
            @click="marcarPago(item)"
          >
            Marcar Pago
          </v-btn>
        </v-col>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark> Atualizar </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ComandaService from "@/services/comanda_service.js";
import FormaPagamentoService from "../../services/forma_pagamento_service";

export default {
  mixins: [Mixin],
  data: () => ({
    dialogDetalhesComandas: false,
    comandasItens: [],
    loading: false,
    formasdePagamento: [],
    formaPagamentoSelecionada: {},
    headers: [
      {
        text: "Procedimento",
        align: "start",
        sortable: false,
        value: "procedimentoDescricao",
      },
      {
        text: "Tipo Procedimento",
        align: "start",
        sortable: false,
        value: "tipoProcedimentoDescricao",
      },
      {
        text: "Valor",
        align: "start",
        sortable: false,
        value: "valor",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: false,
        value: "convenioDescricao",
      },
      {
        text: "Status",
        align: "start",
        sortable: false,
        value: "statusDescricao",
      },
      { text: "Data criado", value: "dataCriado", sortable: false },
      { text: "Ações", align: "center", value: "actions", sortable: false },
    ],
    comandaService: ComandaService.build(),
    formaPagamentoService: FormaPagamentoService.build(),
  }),
  props: {
    comanda: {
      type: Object,
      required: true,
    },
  },
  watch: {
    comanda() {
      this.comandasItens = [];
      this.setComandaItens();
    },
  },
  async mounted() {
    await this.setComandaItens();
    this.getFormasPagamento();
  },
  methods: {
    selecionarFormaPagamento(event, id) {
      this.formaPagamentoSelecionada = Object.assign(
        this.formaPagamentoSelecionada,
        { [id]: event }
      );
    },
    async getFormasPagamento() {
      this.formasdePagamento = await this.formaPagamentoService
        .getFormaPagamentoPaginatedList()
        .then((resp) => resp.json())
        .then((resp) => {
          return resp.data.forma_pagamento.map((item) => {
            return { text: item.descricao, id: item.id };
          });
        });
    },
    async setComandaItens() {
      this.loading = true;
      const response = await this.comandaService.getComandaItensByComandaId(
        this.comanda.comanda_id
      );
      this.comandasItens = response.map((item) => {
        return {
          ...item,
          procedimentoDescricao: item.procedimento_descricao,
          tipoProcedimentoDescricao: item.tipo_procedimento_descricao,
          valor: parseFloat(item.valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
          convenioDescricao: item.tipo_convenio_descricao,
          statusDescricao: item.status_descricao,
          isPago: item.status == 2,
          dataCriado: new Date(
            item.data_criada + " " + item.hora_criada
          ).toLocaleString("pt-BR"),
        };
      });
      this.loading = false;
    },
    async marcarPago(item) {
      this.loading = true;
      await this.comandaService
        .pagarItemComanda(
          item.comanda_id,
          item.comanda_item_id,
          this.formaPagamentoSelecionada[item.comanda_item_id]
        )
        .finally(() => {
          this.loading = false;
          this.setComandaItens();
        });
    },
  },
  computed: {},
};
</script>
