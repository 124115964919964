<template>
  <div>
    <div v-if="pergunta.id_tipo_pergunta===1" class="justify-center">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===2">
      <form-field-tipo-paragrafo :pergunta="pergunta" :disabled="disabled"/>
    </div>
      
    <div v-if="pergunta.id_tipo_pergunta===3">
      <form-field-tipo-radio :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===4">
      <form-field-tipo-check :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===5">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===6">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>

    <div v-if="pergunta.id_tipo_pergunta===7">
      <form-field-tipo-text :pergunta="pergunta" :disabled="disabled"/>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FormFieldTipoText from "@/components/formularios/formulario_campo_text.vue";
import FormFieldTipoParagrafo from "@/components/formularios/formulario_campo_paragrafo.vue";
import FormFieldTipoRadio from "@/components/formularios/formulario_campo_radio.vue";
import FormFieldTipoCheck from "@/components/formularios/formulario_campo_check.vue";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
    disabled:Boolean
  },
  components:{
    FormFieldTipoText,
    FormFieldTipoParagrafo,
    FormFieldTipoRadio,
    FormFieldTipoCheck
  },
  data: () => ({

  }),
  watch: {
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>