<template>
  <div>
    <v-card min-width="400px" style="width: 100%">
      <v-card-title>
        <span class="headline text-center">Detalhes agendamento</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-lg-12">
              <div v-if="detalhes">
                <div class="row">
                  <div class="col-md-4">
                    <v-text-field
                      label="Data"
                      :value="detalhes.data|data"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>
                  
                  <div class="col-md-4">
                    <v-text-field
                      label="Horário inicial"
                      :value="detalhes.horarioInicial"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>  

                  <div class="col-md-4">
                    <v-text-field
                      label="Horário final"
                      :value="detalhes.horarioFinal"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>                    

                  <div class="col-md-12">
                    <v-text-field
                      label="Paciente"
                      :value="this.$_GETTERS_usuario.dados.nome"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                  <div class="col-md-12">
                    <v-text-field
                      label="Procedimento"
                      :value="detalhes.procedimento"
                      placeholder=" "
                      readonly
                    ></v-text-field>
                  </div>

                </div>

              </div>
              <div v-else>
                <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="green"
                ></v-progress-circular>
                <v-alert v-if="error"
                  type="error"
                  dark
                >
                  Erro no carregamento dos dados. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.
                </v-alert>
              </div>
            </div>
          </div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="$emit('close')"
        >
          Fechar
        </v-btn>

        <v-btn
          color="error"
          text
          :loading="loadingDesmarcar"
          @click="confirmDesmarcar = true"
          style="background-color: #ff5252; color: #fff !important;"
        >
          <span v-if="!loadingDesmarcar">
              Desmarcar
          </span>    
          <v-progress-circular
              v-else
              indeterminate
              color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>

      <confirmation-dialog
        v-if="confirmDesmarcar"
        :enabled="confirmDesmarcar"
        :dialogColor="'error'"
        :title="'Desmarcar consulta'"
        :confirmationMessage="'Você tem certeza que deseja desmarcar essa consulta?'"
        :confirmationButtonMessage="'Confirmar'"
        :abortButtonMessage="'Cancelar'"
        :confirmationButtonColor="'error'"
        @abort="confirmDesmarcar = false"
        @confirm="desmarcarConsulta"
    ></confirmation-dialog>
  </div>
</template>

<script>

import ConfirmationDialog from '@/components/dialogs/confirmation'
import Mixin from "@/mixins/vuex_usuario_mixin.js";
import AgendamentoService from "@/services/agendamento_service";

export default {
  mixins:[Mixin],
  components: { ConfirmationDialog },
  props: {
    detalhes: {
      type: Object,
      default: () => {return {}},
      required: false,
      validator: obj => {
        switch (true) {
          case !/^\d{4}-\d{2}-\d{2}$/.test(obj.data):
            return false
          case !/^\d{2}:\d{2}$/.test(obj.horarioInicial):
            return false
          case !/^\d{2}:\d{2}$/.test(obj.horarioFinal):
            return false
          case +obj.idPaciente < 1:
            return false
          case +obj.idAgenda < 1:
            return false
          case (typeof obj.procedimento !== 'string'):
            return false
          default:
            return true
        }
      }
    }
  },
  data: () => ({
    paciente: null,
    loading: false,
    loadingDesmarcar: false,
    error: false,
    confirmDesmarcar: false
  }),
  methods: {
    desmarcarConsulta () {
        this.confirmDesmarcar = false
        const { idAgendamento }  = this.detalhes
        const agendamentoService = new AgendamentoService()
        this.loadingDesmarcar = true
        agendamentoService.desmarcarAgendamento({
          onSucess: status => body => {
            this.$emit('desmarcar', {
              status,
              message: body.message,
              idAgenda: this.detalhes.idAgenda
            })
          },
          onError: error => {
            this.$emit('desmarcar', { error })
          },
          onEnd: () => {
            this.loadingDesmarcar = false
          }
        }, idAgendamento)
    },
  }
};
</script>