import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class FormaPagamentoService extends HTTPService {
  resource = "forma-pagamento";

  static build() {
    return new FormaPagamentoService();
  }

  getFormaPagamentoPaginatedList() {
    return this.get(this.resource);
  }

  async register(formaPagamento) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: formaPagamento,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async editar(formaPagamentoId, formaPagamento) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: formaPagamento,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${formaPagamentoId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async deletar(formaPagamentoId) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      headers: headers,
    };
    return fetch(
      `${global.api.baseURL}/${this.resource}/${formaPagamentoId}/deletar`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
