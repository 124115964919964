<template>
  <div class="">
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Recepção</h3>
      </div>
      <div class="btnEscolherfila">
        <v-btn
          small
          depressed
          color="success"
          :class="{ btn: loading }"
          @click="listarFilasDisponiveis"
        >
          <span v-if="!loading">Local de atendimento</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-dialog v-model="dialogAlocarLocal" max-width="500px">
          <v-card>
            <v-card-title>Selecionar local de atendimento</v-card-title>
            <v-card-text class="">
              <v-select
                :items="selectFilas"
                v-model="filaAlocada"
                :rules="[
                  validationService.required('Campo obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                label="Selecione onde deseja atender"
                item-value="text"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn color="gray" text @click="dialogAlocarLocal = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                color="warning"
                :disabled="!filaAlocada"
                @click="alocarLocal"
                class="dialogButtonEncaminhar"
              >
                <span v-if="!loadingAlocar">Alocar</span>
                <v-progress-circular
                  v-else
                  :width="3"
                  :size="20"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div class="top-section d-flex justify-content-between">
      <div class="senha-container d-flex flex-column w-50">
        <div>
          <h2>senha</h2>
          <h1>{{ infoPaciente.Senha ? infoPaciente.Senha : "-" }}</h1>
        </div>

        <div class="buttons d-flex justify-content-around">
          <v-btn
            small
            depressed
            :disabled="infoPaciente.Senha || !id_filaAlocada"
            color="success"
            :class="{ btn: loading }"
            @click="chamar"
          >
            <span v-if="!loading">Chamar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="primary"
            :class="{ btn: loading }"
            @click="tocar"
          >
            <span v-if="!loading">Tocar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="warning"
            :class="{ btn: loading }"
            @click="encaminhar"
          >
            <span v-if="!loading">Encaminhar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>

          <v-dialog v-model="dialogEncaminhar" max-width="500px">
            <v-card>
              <v-card-title>Encaminhar</v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="selectedTutor"
                  :loading="loadingSearch"
                  :items="tutores"
                  :search-input.sync="searchTutor"
                  hide-no-data
                  hide-selected
                  label="Selecione um tutor"
                  item-text="nome"
                  return-object
                ></v-autocomplete>
              </v-card-text>

              <v-card-text>
                <v-select
                  :items="pets"
                  v-model="selectedPet"
                  label="Selecione um pet"
                  item-text="nome"
                  return-object
                ></v-select>
              </v-card-text>

              <v-card-text>
                <v-select
                  :items="selectEncaminhar"
                  v-model="especialidadeSelecionada"
                  label="Selecione para onde deseja encaminhar"
                  item-value="text"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn color="gray" text @click="dialogEncaminhar = false">
                  Fechar
                </v-btn>
                <v-btn
                  small
                  depressed
                  color="warning"
                  :disabled="!especialidadeSelecionada || !selectedPet"
                  @click="encaminharPara"
                  class="dialogButtonEncaminhar"
                >
                  <span v-if="!loadingEncaminhar">Encaminhar</span>
                  <v-progress-circular
                    v-else
                    :width="3"
                    :size="20"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="error"
            :class="{ btn: loading }"
            @click="finalizar"
          >
            <span v-if="!loading">Finalizar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="primary"
            :class="{ btn: loading }"
            @click="aguardar"
          >
            <span v-if="!loading">Aguardar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>

      <InfoChamada :key="infoChamadaKey" />
    </div>

    <div>
      <Fila
        :token="token"
        :key="infoChamadaKey"
        @chamarSenha="chamarSenhaLista($event)"
      />
    </div>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import Mixin from "@/mixins/vuex_mixin.js";
import axios from "axios";

import InfoChamada from "@/components/recepcao/infoChamada/infoChamada.vue";
import Fila from "@/components/recepcao/fila.vue";
import ValidationService from "@/services/validation_service.js";
import PacienteService from "@/services/paciente_service.js";

export default {
  components: {
    InfoChamada,
    Fila,
  },
  mixins: [Mixin],
  data() {
    return {
      loading: false,
      loadingSearch: false,
      tokenChamefacil: "",
      idSenha: "",
      IdChamadaInterna: "",
      infoPaciente: {},
      infoChamadaKey: 0,
      dialogAlocarLocal: false,
      dialogEncaminhar: false,
      loadingEncaminhar: false,
      tutor_id: "",
      pet_id: "",
      pets: [],
      selectedPet: "",
      tutores: [],
      selectedTutor: "",
      searchTutor: "",
      selectEncaminhar: [],
      especialidadeSelecionada: "",
      selectFilas: [],
      filaAlocada: "",
      id_filaAlocada: "",
      validationService: {},
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
    };
  },
  watch: {
    searchTutor(nome) {
      if (!nome) {
        return;
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
              console.log(this.tutores);
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    selectedTutor() {
      this.selectedPet = "";
      this.pets = this.selectedTutor.pets;
      console.log(this.pets);
    },
  },
  beforeMount() {
    this.id_filaAlocada = localStorage.getItem("idFilaAlocada");
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
    this.infoPaciente = !localStorage.getItem("infoPaciente")
      ? {}
      : JSON.parse(localStorage.getItem("infoPaciente"));
    this.validationService = ValidationService;

    this.listarFilasDisponiveis();
  },
  methods: {
    listarFilasDisponiveis() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(global.api.baseUrlChamefacil + "/LocalChamada/ListarDisponiveis", {
          headers,
        })
        .then(async (resp) => {
          console.log(resp.data);
          this.selectFilas = await resp.data.localChamada.map(function(local) {
            return { id: local.Id, text: local.Nome };
          });
          this.dialogAlocarLocal = true;
        });
    },
    alocarLocal() {
      const headers = { token: this.tokenChamefacil };
      const alocarFila = this.selectFilas.filter(
        (item) => item.text === this.filaAlocada
      );
      this.id_filaAlocada = alocarFila[0].id;

      axios
        .post(
          global.api.baseUrlChamefacil + "/localchamada/alocarlocal",
          { IdLocal: this.id_filaAlocada },
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          localStorage.setItem(
            "idFilaAlocada",
            JSON.stringify(this.id_filaAlocada)
          );
          this.dialogAlocarLocal = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    chamar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/chamarproximainterna",
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data.Senha);
          this.infoPaciente = resp.data.Senha;
          this.idChamadaInterna = resp.data.Senha.IdChamadaInterna;
          console.log(this.idChamadaInterna);
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          this.infoChamadaKey += 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    tocar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/tocarinterna?chamadaInternaId=" +
            this.infoPaciente.IdChamadaInterna,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.loading = false;
        });
    },
    encaminhar() {
      this.especialidadeSelecionada = "";
      this.selectedTutor = "";
      this.selectedPet = "";
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/localChamada/ListarFilasInternas?id=" +
            this.id_filaAlocada,
          { headers }
        )
        .then(async (resp) => {
          this.selectEncaminhar = await resp.data.localChamada.map(function(
            local
          ) {
            return { id: local.Id, text: local.Nome };
          });
          this.loading = false;
          this.dialogEncaminhar = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    encaminharPara() {
      this.loadingEncaminhar = true;
      this.tutor_id = this.selectedTutor.id_cliente;
      this.pet_id = this.selectedPet.id_pet;
      const encaminharPara = this.selectEncaminhar.filter(
        (item) => item.text === this.especialidadeSelecionada
      );
      const id_filaInterna = encaminharPara[0].id;

      const realocarFila = {};
      console.log(realocarFila);

      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/Atendimento/RealocarInternaV2?chamadaInternaId=" +
            this.idChamadaInterna +
            "&filaInternaId=" +
            id_filaInterna +
            "&TutorCodigo=" +
            this.tutor_id +
            "&PetCodigo=" +
            this.pet_id,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.infoChamadaKey += 1;
          this.loadingEncaminhar = false;
          this.dialogEncaminhar = false;
          this.especialidadeSelecionada = "";
          this.IdChamadaInterna = "";
        })
        .catch((error) => {
          this.loadingEncaminhar = false;
          this.dialogEncaminhar = false;
          this.especialidadeSelecionada = "";
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    aguardar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/aguardarinterna?chamadainternaId=" +
            this.infoPaciente.IdChamadaInterna,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.infoChamadaKey += 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    finalizar() {
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/finalizarinterna",
          {
            IdSenha: this.infoPaciente.IdSenha,
            IdLocal: 0,
            IdFilaInterna: 0,
            UsuarioSenha: "",
            UsuarioAtendente: "",
          },
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.infoChamadaKey += 1;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },

    chamarSenhaLista(idChamada) {
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/chamarsenhainternaespecifica?chamadaInternaId=" +
            idChamada,
          {},
          { headers }
        )
        .then((resp) => {
          this.infoPaciente = resp.data.Senha;
          this.idChamadaInterna = resp.data.Senha.IdChamadaInterna;
          console.log(this.infoPaciente);
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          this.infoChamadaKey += 1;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 100px;
}
.buttons {
  flex-wrap: wrap;
}

.buttons .btn {
  height: 30px;
  width: 95px;
}
.dialogButtonEncaminhar {
  height: 20px;
  width: 120px;
}
.btnEscolherfila {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
@media (max-width: 1080px) {
  .top-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .senha-container {
    flex-direction: row !important;
    gap: 2rem;
    margin-top: 1rem;
  }
  .buttons {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}
</style>
