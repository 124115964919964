<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Atestado</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-text-field
            v-model="dias"
            label="Quantidade de dias"
          ></v-text-field>
          <v-text-field
            v-model="codigoCFM"
            label="Código CID"
          ></v-text-field>
        </v-container>
         <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
               @click="$emit('close')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-app-primary btn-padding font-weight-bold"
              @click="gerarPdf()"
            >
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    dias:"",
    codigoCFM:""
  }),
  methods:{
    gerarPdf(){
      if (this.dias =="") {3
        console.log("Faltou os dias")
        return
      }
      this.$emit("gerarPDF", {
        doc:"atestado",
        dias: this.dias,
        codigoCFM: this.codigoCFM
      })
      this.dias =""
      this.codigoCFM =""
    }
  }
};
</script>