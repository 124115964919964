<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Unidades
        </h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :search="search"
      :headers="headers"
      :items="unidades"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
              v-model="dialogCadastro"
              max-width="500px"
              persistent
              fullscreen
              transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="allowCadastrarBtn" color="#3E682A" dark class="mb-2" v-bind="attrs" v-on="on">
                Nova Unidade
              </v-btn>
            </template>
            <v-card>
              <v-toolbar style="background-color: #3e682a; color: #fff">
                <v-btn icon dark @click="dialogCadastro = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Cadastrar unidade</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                </v-toolbar-items>
              </v-toolbar>
              <cadastro-novo-unidade v-if="dialogCadastro"  @close="dialogCadastro = false" @response="atualizarListaUnidades"/>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
          ></v-text-field>
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <cadastro-edicao-unidade v-if="unidade_detalhe" :unidade="unidade_detalhe" :visualizar="visualizar" :key="render" @close="save($event)" />
          </v-dialog>
          <v-dialog
            v-model="dialogObs"
            fullscreen
            hide-overlay
            scrollable
            transition="dialog-bottom-transition"
          >
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item, true)"> mdi mdi-eye </v-icon>
        <v-icon v-if="allowEditarBtn" small class="mr-2" @click="editItem(item, false )"> fas fa-edit </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarListaUnidades">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CadastroEdicaoUnidade from "@/components/unidades/cadastro_edicao_unidade.vue";
import CadastroNovoUnidade from "@/components/unidades/cadastro_novo_unidade.vue";
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../services/unidade_service";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";

export default {
  mixins:[Mixin],
  components: {
    CadastroEdicaoUnidade, CadastroNovoUnidade
  },

  data: () => ({
    unidade_detalhe: {},
    render: 0,
    dialog: false,
    dialogObs: false,
    loading: false,
    search: "",
    totalList:10,
    options:{
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc:false,
    },
    dialogCadastro: false,
    unidade: {},
    visualizar: false,
    unidades: [],
    headers: [
      {
        text: "Nome fantasia",
        align: "start",
        sortable: true,
        value: "nomeFantasia",
      },
      { text: "Razão social", value: "razaoSocial",  sortable: false},
      { text: "CNPJ", value: "cnpj",  sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    defaultItem: {
      nome: "",
      cpf: "",
      email: "",
    },
    services: {
      unidadeService: UnidadeService.build(),
      authService: AuthService.build()
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Novo Procedimento"
        : "Editar Procedimento";
    },
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(endpoints["UnidadeController:POST->register"])
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(endpoints["UnidadeController:POST->updateUnidadeData/$1"])
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    options:{
      handler (){
        this.options.desc = this.options.sortDesc[0] ?? false
        this.atualizarListaUnidades();
      },
      deep:true
    },
    search(){
      this.atualizarListaUnidades();
    }
  },

  mounted() {
    this.atualizarListaUnidades();
  },

  methods: {
    forceRender(){
      this.render+=1;
    },
    atualizarListaUnidades() {
      // const unidadeService = new UnidadeService();
      this.loading = true;
      this.unidades=[];
      this.services.unidadeService.getUnidadePaginatedList({
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        search: this.search,
        order: this.options.ordenacao,
        desc: this.options.desc
      }).then(async (response) => {
        if (response.status !== 200) return;
        const {data} = await response.json()
        const {unidades, pagination} = data;
        this.totalList = pagination.num_rows;
        this.unidades = unidades.map(unidade => {
          return {
            pessoaJuridicaId: unidade.pessoa_juridica_id,
            nomeFantasia: unidade.nome_fantasia,
            razaoSocial: unidade.razao_social,
            cnpj: unidade.cnpj,
            // pessoaId: unidade.pessoa_id,
            telefone1: unidade.telefone_1,
            telefone2: unidade.telefone_2,
            observacao: unidade.observacao,
            // enderecoId: unidade.endereco_id,
            cep: unidade.cep,
            numero: unidade.numero,
            complemento: unidade.complemento,
            referencias: unidade.referencias,
            // cepId: unidade.cep_id,
            estado: unidade.estado,
            cidade: unidade.cidade,
            bairro: unidade.bairro,
            logradouro: unidade.logradouro,
          }
        })

      }).catch((error) => {
        console.error({error})
      }).finally(() => {
        this.loading = false;
      })
    },

    loadingUnidade(pessoaJuridicaId) {
      this.unidade_detalhe = this.unidades.find(u => u.pessoaJuridicaId === pessoaJuridicaId)
      console.log(this.unidade_detalhe)
    },

    editItem(item, visualizar) {
      this.editedIndex = this.unidades.indexOf(item);
      this.unidade = item;
      this.visualizar = visualizar;
      this.editedItem = Object.assign({}, item);
      this.loadingUnidade(this.unidade.pessoaJuridicaId);
      this.render = 1;
      this.dialog = true;

    },
    editItemObs(item) {
      this.forceRender();
      this.editedIndex = this.unidades.indexOf(item);
      this.unidade = item;
      this.editedItem = Object.assign({}, item);
      this.dialogObs = true;
    },

    close() {
      this.dialog = false;
      this.dialogCadastro = false;
      this.dialogObs = false;
      this.atualizarListaUnidades();
      this.forceRender();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      if (this.editedIndex > -1) {
        Object.assign(this.unidades[this.editedIndex], this.editedItem);
      } else {
        this.unidades.push(this.editedItem);
      }
      this.unidade_detalhe = false
      this.options.ordenacao = false
      this.options.desc = false
      this.close();
      this.atualizarListaUnidades();
      this.forceRender();
    },
  },
};
</script>
