<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <div class="d-flex justify-content-between w-100">
          <h3 class="mt-0 mb-2 font-weight-bold text-left">Agendamento</h3>
          <div class="d-flex">
            <div class="d-flex ml-5">
              <i class="mdi mdi-brightness-1 mr-1" style="color: grey"></i>
              <span>Horário Indisponível</span>
            </div>
            <div class="d-flex ml-5">
              <i class="mdi mdi-brightness-1 mr-1" style="color: #3e682a"></i>
              <span>Horário Disponível</span>
            </div>
            <div class="d-flex ml-5">
              <i class="mdi mdi-brightness-1 mr-1" style="color: #188ae2"></i>
              <span>Horário Marcado</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Calendario -->
    <v-row class="fill-height">
      <v-col>
        <calendario :agendaMedico="true" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Calendario from "@/components/agendamentos/calendario.vue";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  components: { Calendario },
  mixins: [Mixin],
  data: () => ({}),

  watch: {},

  mounted() {},
  computed: {},
  methods: {},
};
</script>
