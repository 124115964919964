import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { global } from "@/config/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usuario: {},
    snackbar: {
      message: "",
      color: "",
    },
    data: {},
    token: "",
    perfil: { id: 0, description: "home" },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  getters: {
    getUsuario: (state) => {
      return state.usuario;
    },
    getSnackbar: (state) => {
      return state.snackbar;
    },
    getToken: (state) => {
      return state.token;
    },
    getPerfil: (state) => {
      return state.perfil;
    },
    getData: (state) => {
      return state.data;
    },
    getUnidade: (state) => {
      return state.unidade;
    },
  },
  mutations: {
    setUsuario: (state, usuario) => {
      state.usuario = usuario;
    },
    setSnackbar: (state, snackbar) => {
      state.snackbar = snackbar;
    },
    setToken: (state, token) => {
      state.token = token;
    },
    setPerfil: (state, perfil) => {
      state.perfil = perfil;
    },
    setData: (state, data) => {
      state.data = data;
    },
    setUnidade: (state, unidade) => {
      state.unidade = unidade;
    },
  },
  actions: {
    setUsuario({ commit }, usuario) {
      commit("setUsuario", usuario);
    },
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    setPerfil({ commit }, perfil) {
      commit("setPerfil", perfil);
    },
    setData({ commit }, data) {
      commit("setData", data);
    },
    setUnidade({ commit }, unidade) {
      commit("setUnidade", unidade);
    },
    showSnackbarMessage({ commit }, { message, color }) {
      const snackbar = { message, color };
      const snackbarStateIsValid =
        color === ""
          ? true
          : Object.keys(global.snackbar.colors).includes(color);
      if (!snackbarStateIsValid) {
        throw new Error("Invalid state provided to snackbar.");
      } else {
        commit("setSnackbar", snackbar);
      }
    },
  },
  modules: {},
});
