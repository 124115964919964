<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Filas de atendimento
        </h3>
      </div>
      <div class="btnEscolherfila">
        <v-btn
          small
          depressed
          color="success"
          :class="{ btn: loading }"
          @click="listarFilasDisponiveis"
        >
          <span v-if="!loading">Local de atendimento</span>
          <v-progress-circular
            v-else
            :width="3"
            :size="20"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>

        <v-dialog v-model="dialogAlocarLocal" max-width="500px">
          <v-card>
            <v-card-title>Selecionar local de atendimento</v-card-title>
            <v-card-text class="">
              <v-select
                :items="selectFilas"
                v-model="filaAlocada"
                :rules="[
                  validationService.required('Campo obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                label="Selecione onde deseja atender"
                item-value="text"
              ></v-select>
            </v-card-text>
            <v-card-actions>
              <v-btn color="gray" text @click="dialogAlocarLocal = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                color="warning"
                :disabled="!filaAlocada"
                @click="alocarLocal"
                class="dialogButtonEncaminhar"
              >
                <span v-if="!loadingAlocar">Alocar</span>
                <v-progress-circular
                  v-else
                  :width="3"
                  :size="20"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <div class="top-section d-flex justify-content-between">
      <div class="senha-container d-flex flex-column w-50">
        <div>
          <h2>senha</h2>
          <h1>{{ infoPaciente.Senha ? infoPaciente.Senha : "-" }}</h1>
        </div>

        <div class="buttons d-flex justify-content-around">
          <v-btn
            small
            depressed
            :disabled="!!infoPaciente.Senha || !id_filaAlocada"
            color="success"
            :class="{ btn: loading }"
            @click="chamar"
          >
            <span v-if="!loading">Chamar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>

          <v-btn
            :disabled="!infoPaciente.PetCodigo || dialogAtender"
            small
            depressed
            color="success"
            @click="dialogAtender = true"
          >
            Atender
          </v-btn>

          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="primary"
            :class="{ btn: loading }"
            @click="tocar"
          >
            <span v-if="!loading">Tocar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="warning"
            :class="{ btn: loading }"
            @click="encaminhar"
          >
            <span v-if="!loading">Encaminhar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="20"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>

          <v-dialog v-model="dialogEncaminhar" max-width="500px">
            <v-card>
              <v-card-title>Encaminhar</v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="selectedTutor"
                  :loading="loadingSearch"
                  :items="tutores"
                  :search-input.sync="searchTutor"
                  hide-no-data
                  hide-selected
                  label="Selecione um tutor"
                  item-text="nome"
                  return-object
                ></v-autocomplete>
              </v-card-text>

              <v-card-text>
                <v-select
                  :items="pets"
                  v-model="selectedPet"
                  label="Selecione um pet"
                  item-text="nome"
                  return-object
                ></v-select>
              </v-card-text>

              <v-card-text>
                <v-select
                  :items="selectEncaminhar"
                  v-model="especialidadeSelecionada"
                  label="Selecione para onde deseja encaminhar"
                  item-value="text"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn color="gray" text @click="dialogEncaminhar = false">
                  Fechar
                </v-btn>
                <v-btn
                  small
                  depressed
                  color="warning"
                  :disabled="!especialidadeSelecionada || !selectedPet"
                  @click="encaminharPara"
                  class="dialogButtonEncaminhar"
                >
                  <span v-if="!loadingEncaminhar">Encaminhar</span>
                  <v-progress-circular
                    v-else
                    :width="3"
                    :size="20"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="error"
            :class="{ btn: loading }"
            @click="finalizar"
          >
            <span v-if="!loading">Finalizar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            small
            depressed
            :disabled="!id_filaAlocada"
            color="primary"
            :class="{ btn: loading }"
            @click="aguardar"
          >
            <span v-if="!loading">Aguardar</span>
            <v-progress-circular
              v-else
              :width="3"
              :size="25"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>

      <InfoChamada :key="infoChamadaKey" />
    </div>

    <div>
      <Fila
        :token="token"
        :key="infoChamadaKey"
        @chamarSenha="chamarSenhaLista($event)"
      />
    </div>

    <default-dialog
      :value="dialogAtender"
      color="green darken-3"
      title="Selecione sua unidade"
    >
      <div class="p-2">
        <div class="col-lg-12">
          <div class="mx-auto">
            <h3>Escolha o procedimento:</h3>
            <div class="mt-8">
              <v-select
                v-model="procedimento"
                :items="procedimentos"
                :loading="procedimentoLoading"
                background-color="white"
                label="Procedimentos"
                item-text="descricao"
                return-object
                color="black"
                solo
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.descricao" />
                    <v-list-item-subtitle
                      class="mt-1"
                      v-html="item.tipoProcedimento"
                    />
                  </v-list-item-content>
                </template>
              </v-select>
              <v-btn @click="iniciarAtendimento">Iniciar atendimento</v-btn>
            </div>
          </div>
        </div>
      </div>
    </default-dialog>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import Mixin from "@/mixins/vuex_mixin.js";
import axios from "axios";
import ProcedimentoService from "@/services/procedimento_service";
import InfoChamada from "@/components/recepcao_medico/infoChamada/infoChamada.vue";
import Fila from "@/components/recepcao_medico/fila.vue";
import ValidationService from "@/services/validation_service.js";
import Default from "@/components/dialogs/default";
import AgendamentoService from "@/services/agendamento_service";
import PacienteService from "@/services/paciente_service.js";

export default {
  watch: {
    searchTutor(nome) {
      if (!nome) {
        return;
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
              console.log(this.tutores);
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    selectedTutor() {
      this.selectedPet = "";
      this.pets = this.selectedTutor.pets;
      console.log(this.pets);
    },
  },
  components: {
    InfoChamada,
    Fila,
    DefaultDialog: Default,
  },
  mixins: [Mixin],
  data() {
    return {
      loading: false,
      tokenChamefacil: "",
      idSenha: "",
      infoPaciente: {},
      infoChamadaKey: 0,
      dialogAlocarLocal: false,
      selectFilas: [],
      filaAlocada: "",
      id_filaAlocada: "",
      dialogEncaminhar: false,
      loadingEncaminhar: false,
      loadingAlocar: false,
      tutores: [],
      searchTutor: "",
      pets: [],
      token: "",
      loadingSearch: false,
      selectEncaminhar: [
        // { text: "especialidade 1" },
        // { text: "especialidade 2" },
        // { text: "especialidade 3" },
        // { text: "especialidade 4" },
      ],
      encaminharSelecionado: "",
      validationService: {},
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      procedimento: 0,
      procedimentos: [],
      procedimentoLoading: false,
      pacienteEmAtendimento: {},
      dialogAtender: false,
      agendamentoService: AgendamentoService.build(),
      procedimentosService: ProcedimentoService.build(),
      selectedTutor: "",
      selectedPet: "",
      especialidadeSelecionada: "",
      tutor_id: 0,
      pet_id: 0,
      id_filaInterna: 0,
    };
  },
  beforeMount() {
    this.id_filaAlocada = localStorage.getItem("idFilaAlocada");
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
    this.infoPaciente = !localStorage.getItem("infoPaciente")
      ? {}
      : JSON.parse(localStorage.getItem("infoPaciente"));
    this.validationService = ValidationService;

    this.listarFilasDisponiveis();
    this.listarProcedimentos();
  },
  methods: {
    async listarProcedimentos() {
      this.procedimentoLoading = true;
      this.procedimentos = await this.procedimentosService.findAll();
      this.procedimentoLoading = false;
    },
    listarFilasDisponiveis() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(global.api.baseUrlChamefacil + "/LocalChamada/ListarDisponiveis", {
          headers,
        })
        .then(async (resp) => {
          console.log(resp.data);
          this.selectFilas = await resp.data.localChamada.map(function(local) {
            return { id: local.Id, text: local.Nome };
          });
          this.dialogAlocarLocal = true;
        });
    },
    alocarLocal() {
      const headers = { token: this.tokenChamefacil };
      const alocarFila = this.selectFilas.filter(
        (item) => item.text === this.filaAlocada
      );
      this.id_filaAlocada = alocarFila[0].id;

      axios
        .post(
          global.api.baseUrlChamefacil + "/localchamada/alocarlocal",
          { IdLocal: this.id_filaAlocada },
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          localStorage.setItem(
            "idFilaAlocada",
            JSON.stringify(this.id_filaAlocada)
          );
          this.dialogAlocarLocal = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: "",
            color: "sucess",
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    chamar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/chamarproximainterna",
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = resp.data.Senha;
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          this.infoChamadaKey += 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    tocar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/tocarinterna?chamadaInternaId=" +
            this.infoPaciente.IdChamadaInterna,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.loading = false;
        });
    },
    encaminhar() {
      this.especialidadeSelecionada = "";
      this.selectedTutor = "";
      this.selectedPet = "";
      this.loading = true;
      this.dialogEncaminhar = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/localChamada/ListarFilasInternas?id=" +
            this.id_filaAlocada,
          { headers }
        )
        .then(async (resp) => {
          console.log({ resp });
          this.selectEncaminhar = await resp.data.localChamada.map(function(
            local
          ) {
            return { id: local.Id, text: local.Nome };
          });
          this.loading = false;
          this.dialogEncaminhar = true;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    encaminharPara() {
      this.loadingEncaminhar = true;
      this.tutor_id = this.selectedTutor.id_cliente;
      this.pet_id = this.selectedPet.id_pet;
      const encaminharPara = this.selectEncaminhar.filter(
        (item) => item.text === this.especialidadeSelecionada
      );
      const id_filaInterna = encaminharPara[0].id;

      const realocarFila = {};
      console.log(realocarFila);

      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/Atendimento/RealocarInternaV2?chamadaInternaId=" +
            this.infoPaciente.IdChamadaInterna +
            "&filaInternaId=" +
            id_filaInterna +
            "&TutorCodigo=" +
            this.tutor_id +
            "&PetCodigo=" +
            this.pet_id,
          {},
          { headers }
        )
        .then((resp) => {
          console.log({ resp });
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.infoChamadaKey += 1;
          this.loadingEncaminhar = false;
          this.dialogEncaminhar = false;
          this.especialidadeSelecionada = "";
        })
        .catch((error) => {
          console.log({ error });
          this.loadingEncaminhar = false;
          this.dialogEncaminhar = false;
          this.especialidadeSelecionada = "";
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    aguardar() {
      this.loading = true;
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/aguardarinterna?chamadainternaId=" +
            this.infoPaciente.IdChamadaInterna,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.infoChamadaKey += 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },
    finalizar() {
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil + "/atendimento/finalizarinterna",
          {
            IdSenha: this.infoPaciente.IdSenha,
            IdLocal: 0,
            IdFilaInterna: 0,
            UsuarioSenha: "",
            UsuarioAtendente: "",
          },
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = {};
          localStorage.removeItem("infoPaciente");
          this.infoChamadaKey += 1;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.request.statusText);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        });
    },

    chamarSenhaLista(idChamada) {
      const headers = { token: this.tokenChamefacil };
      axios
        .post(
          global.api.baseUrlChamefacil +
            "/atendimento/chamarsenhainternaespecifica?chamadaInternaId=" +
            idChamada,
          {},
          { headers }
        )
        .then((resp) => {
          console.log(resp.data);
          this.infoPaciente = resp.data.Senha;
          localStorage.setItem("infoPaciente", JSON.stringify(resp.data.Senha));
          this.infoChamadaKey += 1;
          this.loading = false;
        });
    },
    async iniciarAtendimento() {
      if (!this.infoPaciente.PetCodigo) {
        alert(
          "Esse paciente não possui pet associado. Verificar no chamefacil se o cadastro está correto."
        );
        return;
      }
      if (!this.procedimento?.id) {
        alert("Escolha um procedimento.");
        return;
      }
      const body = {
        petId: this.infoPaciente.PetCodigo,
        unidadeId: JSON.parse(sessionStorage.getItem("vuex")).unidade.id,
        medicoId: ~~JSON.parse(sessionStorage.getItem("vuex")).usuario.dados
          .medicoId,
        procedimentoId: this.procedimento.id,
        tipoProcedimentoId: this.procedimento.tipoProcedimentoId,
      };
      await this.agendamentoService.agendarConsultaPrimeiroSlotLivre(body);
      await this.$router.replace({ name: "dashboard" });
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 100px;
}
.buttons {
  flex-wrap: wrap;
}

.buttons .btn {
  height: 30px;
  width: 95px;
}
.btnEscolherfila {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
.dialogButtonEncaminhar {
  height: 20px;
  width: 120px;
}
@media (max-width: 1080px) {
  .top-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .senha-container {
    flex-direction: row !important;
    gap: 2rem;
    margin-top: 1rem;
  }
  .buttons {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}
</style>
