<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Comandas</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="comandas"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="btn ml-5"
          :disabled="!item.comandaEstaAberta"
          @click="fecharComanda(item)"
          >Fechar comanda</v-btn
        >
        <v-icon class="ml-4" @click="showDetalhesComandas(item)">
          mdi mdi-magnify
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark> Atualizar </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogDetalhesComandas"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark style="background-color: #3e682a; color: #fff">
          <v-btn icon dark @click="dialogDetalhesComandas = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Comanda Detalhes </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items> </v-toolbar-items>
        </v-toolbar>
        <modal-comanda-detalhes :comanda="comandaDetalhes" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ComandaService from "@/services/comanda_service.js";
import ModalComandaDetalhes from "@/components/pacientes/modal_comanda_detalhes.vue";

export default {
  mixins: [Mixin],
  components: {
    ModalComandaDetalhes,
  },
  data: () => ({
    dialogDetalhesComandas: false,
    comandas: [],
    comandaDetalhes: {},
    loading: false,
    headers: [
      {
        text: "Pet",
        align: "start",
        sortable: false,
        value: "petNome",
      },
      {
        text: "Unidade",
        align: "start",
        sortable: false,
        value: "unidade",
      },
      { text: "Data de Inicio", value: "dataInicio", sortable: false },
      { text: "Data Fechamento", value: "dataFim", sortable: false },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    comandaService: ComandaService.build(),
  }),
  props: {
    tutor: {
      type: Object,
      required: true,
    },
  },
  watch: {
    tutor() {
      this.comandas = [];
      this.setComandas();
    },
  },
  async mounted() {
    await this.setComandas();
  },
  methods: {
    async setComandas() {
      this.loading = true;
      const response = await this.comandaService.getComandasByClienteId(
        this.tutor.id_cliente
      );
      this.comandas = response.map((comanda) => {
        return {
          ...comanda,
          petNome: comanda.pet_nome,
          unidade: comanda.unidade_nome_fantasia,
          dataInicio: comanda.data_criada + " " + comanda.hora_criada,
          dataFim:
            comanda.data_fechamento !== "1900-01-01"
              ? comanda.data_fechamento + " " + comanda.hora_fechamento
              : "Em aberto",
          comandaEstaAberta: comanda.data_fechamento === "1900-01-01",
        };
      });
      this.loading = false;
    },
    showDetalhesComandas(comanda) {
      this.dialogDetalhesComandas = true;
      this.comandaDetalhes = comanda;
    },

    async fecharComanda(comanda) {
      const success = await this.comandaService.fecharComanda(
        comanda.comanda_id ?? 0
      );
      if (success) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "A comanda foi fechada com sucesso.",
          color: "sucess",
        });
        await this.setComandas();
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Ocorreu um erro no fechamento da comanda.",
          color: "error",
        });
      }
    },
  },
  computed: {},
};
</script>
