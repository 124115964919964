<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Edição de Espécie</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-12 form-group clearfix mb-3">
          <label for="nome">Nome da Espécie</label>
          <v-text-field
            v-model="especieNome"
            placeholder="Nome da Espécie"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="salvarEdicao"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Salvar Edição </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import EspecieService from "../../services/especie_service";

export default {
  mixins: [Mixin],

  data: () => ({
    singleSelect: false,
    loading: false,
    formCadastro: "",
    especieNome: "",
    especieId: 0,
    especieService: EspecieService.build(),
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  props: {
    especie: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderLoading = true;
    this.formCadastro = document.forms[0];
    this.especieId = this.especie.especieId;
    this.especieNome = this.especie.nome;
    this.renderLoading = false;
  },
  methods: {
    async salvarEdicao() {
      this.loading = true;
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("nome", this.especieNome);
        const response = await this.especieService.editarEspecie(
          this.especieId,
          formData
        );
        this.$_ACTIONS_showSnackbarMessage({
          message:
            response.status === 200
              ? "A espécie foi atualizado com sucesso."
              : this.$global.messages.internalServerError,
          color: response.status === 200 ? "sucess" : "error",
        });
        response.status === 200 && this.$emit("response", this.especie);
      } catch (e) {
        console.error({ e });
      }
      this.loading = false;
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
