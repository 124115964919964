<template>
  <div>
    <!-- filtro -->
    <div class="my-2">
        <!-- <v-row justify="center">
          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>
                  <h5>Filtrar</h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <filtroAgendamento 
                  @filtro="filtrarCalendario($event)"
                  @limparFiltro="atualizarCalendario();calendario.typeView = 'month'"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row> -->
    </div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"
          >Hoje</v-btn
        >
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ calendario.labels[calendario.typeView] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="calendario.typeView = 'day'">
              <v-list-item-title>{{ calendario.labels.day }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'week'">
              <v-list-item-title>{{
                calendario.labels.week
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="calendario.typeView = 'month'">
              <v-list-item-title>{{
                calendario.labels.month
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-calendar
        v-show="!loading"
        ref="calendar"
        v-model="calendario.focus"
        color="primary"
        :events="horarios"
        :event-color="getEventColor"
        :type="calendario.typeView"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
        v-on:moved="chengEvent"
      >
      </v-calendar>
    </v-sheet>

    <!-- MODAL - AGENDA -->
      <v-dialog v-model="dialog" max-width="500px">
        <modal-agendar-paciente v-if="modalMarcarAgendamento"
          @close="dialog = false"
          :detalhesAgenda="detalhesAgenda"
          @submit="tratarRespostaAgendar"
        />
        <modal-detalhes-agenda v-if="modalDetalhesAgenda"
          :detalhes="detalhesAgenda"
          @close="close()"
          @desmarcar="tratarRespostaDesmarcar"
        />
      </v-dialog>
      <!--MODAL HORÁRIO OCUPADO  -->
      <v-dialog
        v-model="dialogOculpado"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
              Horário indisponível
          </v-card-title>
          <v-card-text>
            <span>Este horário está indiponivel, verifique os horários com a cor verde</span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="close()"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import AgendaService from "@/services/agenda_service.js";
import ModalAgendarPaciente from "@/components/agendamentos/modal_agendamento_paciente.vue";
import ModalDetalhesAgenda from "@/components/agendamentos/modal_detalhes_agenda_paciente.vue";
// import FiltroAgendamento from '@/components/agendamentos/filtro_agendamento.vue'
import Mixin from '@/mixins/vuex_mixin.js'
import { global } from '@/config/constants.js'

export default {
  mixins:[Mixin],
  components: {
    ModalAgendarPaciente,
    ModalDetalhesAgenda,
    // FiltroAgendamento,
  },

  beforeMount() {
    let data = new Date();
    this.pesquisa_periodo(data),
    this.atualizarCalendario();
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  data: () => ({
    focusCalendario: "",
    dialogOculpado: false,
    dialog: false,
    loading: false,
    corHorarioOcupado: 'grey',
    corHorarioOcupadoPaciente: '#3e682a',
    corHorarioLivre: 'primary',
    typeViewCalendario: "month",
    labelsCalendario: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
    },
    calendario: {
      labels: {
        month: "Mês",
        week: "Semana",
        day: "Dia",
      },
      focus: "",
      typeView: "month",
    },
    modalMarcarAgendamento: false,
    modalDetalhesAgenda:false,
    horarios: [],
    detalhesAgenda: {},
    params:[],
  }),
  methods: {
    pesquisa_periodo(data){
      let ano = data.getFullYear();
      let mes = data.getMonth();
      let di = new Date(ano, mes, -8) ;
      let df = new Date(ano, mes + 1, +8);
      this.params['data_inicio'] = di.toISOString().substr(0,10),
      this.params['data_fim'] = df.toISOString().substr(0,10)
    },
    viewDay({ date }) {
      this.calendario.focus = date;
      this.calendario.typeView = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.calendario.focus = "";
    },
    chengEvent(e){
      this.pesquisa_periodo(new Date(e.year, e.month - 1 === 0 ? 12 : e.month - 1, 1))
      this.atualizarCalendario()
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    close(){
      this.dialogOculpado = false
      this.dialog = false
    },
    showEvent(context) {
      const eventParsed = context.eventParsed;
      const dataHorarioSelecionado =
        eventParsed.start.date + " " + eventParsed.start.time;
      const horarioObj = this.horarios.find((horario) => {
        return horario.start === dataHorarioSelecionado;
      });
      const marcado = horarioObj.marcado;
      const cancelado = horarioObj.cancelado;
      const vencido = horarioObj.horariosVencido;
      this.dialog = true;
      if(!marcado && !cancelado && !vencido){
        this.detalhesAgenda = horarioObj
        this.modalMarcarAgendamento = true;
        this.dialogOculpado = false;
        this.modalDetalhesAgenda = false;
      }else{
        this.detalhesAgenda = null
        if(this.$_GETTERS_usuario.dados.id_cliente == horarioObj.idCliente && !cancelado){
          this.modalMarcarAgendamento = false;
            this.dialogOculpado = false;
            this.modalDetalhesAgenda = true;
            this.detalhesAgenda = {
              idAgenda: horarioObj.idAgenda,
              procedimento: horarioObj.procedimento,
              idPaciente: horarioObj.idPaciente,
              idAgendamento: horarioObj.idAgendamento,
              horarioInicial: horarioObj.horarioInicial,
              horarioFinal: horarioObj.horarioFinal,
              data: horarioObj.data
            }
        }else{
          this.modalMarcarAgendamento = false;
          this.modalDetalhesAgenda = false;
          this.dialogOculpado = true;
        }
      }
    },
    tratarRespostaAgendar (event) {
      if (event.error) {
          this.$_ACTIONS_showSnackbarMessage({
              message: 'Um erro aconteceu durante o processamento da sua solicitação.',
              color: 'error'
          })
      } else {
        const requestWasSucessfull = event.status === 200
        this.$_ACTIONS_showSnackbarMessage({
            message: event.message,
            color: requestWasSucessfull ? 'sucess' : 'error'
        })
        this.dialog = !requestWasSucessfull
        if (requestWasSucessfull) {
          const { request } = event
          for (let i = 0, len = this.horarios.length; i < len; i++) {
            if (this.horarios[i].idAgenda === this.detalhesAgenda.idAgenda) {
              this.horarios[i].marcado = true
              this.horarios[i].idPaciente = request.id_paciente
              this.horarios[i].idCliente = request.id_cliente
              this.horarios[i].idAgendamento = request.id_agendamento
              this.horarios[i].color = this.corHorarioOcupadoPaciente
              this.horarios[i].procedimento = request.procedimento
              break
            }
          }
        }
      }
    },
    tratarRespostaDesmarcar (event) {
      if (event.error) {
        this.$_ACTIONS_showSnackbarMessage({
            message: 'Um erro aconteceu durante o processamento da sua solicitação.',
            color: 'error'
        })
      } else {
        const requestWasSucessfull = event.status === 200
        this.$_ACTIONS_showSnackbarMessage({
            message: event.message,
            color: requestWasSucessfull ? 'sucess' : 'error'
        })
        this.dialog = !requestWasSucessfull
        if (requestWasSucessfull) {
          for (let i = 0, len = this.horarios.length; i < len; i++) {
            if (this.horarios[i].idAgenda === this.detalhesAgenda.idAgenda) {
              this.horarios[i].marcado = false
              this.horarios[i].idPaciente = 0
              this.horarios[i].idAgendamento = 0
              this.horarios[i].procedimento = ''
              this.horarios[i].color = this.corHorarioOcupado
              this.horarios[i].cancelado = true

              break
            }
          }
        }
      }
    },
    atualizarCalendario() {
      this.params['id_tipo_procedimento'] = global.consultas.idTipoProcedimentoConsulta
      const agendaService = new AgendaService();
      const idMedico = 1;
      this.loading = true;
      agendaService.getAgendaList(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.horarios = [];
              console.log(body)
              body.data.agendas.forEach((horario) => {
                const horarioAgenda = {
                  name: horario.tipo_procedimento,
                  idAgenda: horario.id,
                  procedimento: horario.procedimento,
                  start: horario.data + " " + horario.hora_inicio,
                  data: horario.data,
                  horarioInicial: horario.hora_inicio,
                  horarioFinal: horario.hora_final,
                  idAgendamento: horario.id_agendamento,
                  idPaciente: horario.id_paciente,
                  idCliente: horario.id_cliente,
                  end: horario.data + " " + horario.hora_final,
                  color: horario.id_agendamento ? this.definir_cor(true,horario.id_cliente, horario.cancelado_em, horario.data) : this.definir_cor(false,horario.id_cliente, horario.cancelado_em, horario.data),
                  timed: false,
                  marcado: !!horario.id_agendamento,
                  cancelado: (horario.cancelado_em === "" ||horario.cancelado_em === "0000-00-00 00:00:00") ? false : true,
                  horariosVencido: this.horarioVencido(horario.data),
                };
                this.horarios.push(horarioAgenda);
              });
            } else {
              console.error("error");
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: 'error'
            })
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        idMedico, 
        this.params,
      );
    },
    definir_cor(ocupado, cliente, cancelado_em, data){
      
      let datahorario = new Date(data).toISOString().split("T")[0];
      let datahj = new Date().toISOString().split("T")[0]
      if(this.$_GETTERS_usuario.idCliente == cliente){
        return this.corHorarioOcupadoPaciente;
      }
      
      if(ocupado && cancelado_em === "" || ocupado && cancelado_em === "0000-00-00 00:00:00"){
        return this.corHorarioOcupado;
      }
      if (cancelado_em != "" && cancelado_em != "0000-00-00 00:00:00" ||datahj > datahorario){
        return this.corHorarioOcupado;
      }
      if (!ocupado && cancelado_em === "" || !ocupado && cancelado_em === "0000-00-00 00:00:00"  ) {
        return this.corHorarioLivre;
      }
    },
    horarioVencido(data){
      let datahorario = new Date(data).toISOString().split("T")[0];
      let datahj = new Date().toISOString().split("T")[0]
      return (datahorario < datahj)?true:false;
    },
    filtrarCalendario(event) {
      this.loading = true;
      const procedimento = event[0];
      const paciente = event[1];
      const data = event[2];
        if(data){
          this.horarios = this.horarios.filter(function(horario){
            return horario.data == data
          })
        }
        if(paciente){
          this.horarios = this.horarios.filter(function (horario){
            console.log(horario)
            return horario.idPaciente == paciente
          })
        }
        if(procedimento){
          this.horarios = this.horarios.filter(function(horario){
            return horario.procedimento == procedimento
          })
        }
        if (data) {
          this.calendario.typeView = 'day'
          this.calendario.focus = data;
        }
        this.loading = false;
    },
  },
};
</script>
