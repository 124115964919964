<template>
  <v-card :elevation="0">
    <v-form ref="form" class="mt-4">
      <div class="col-12 row">
        <div class="col-4">
          <v-select
            clearable
            outlined
            v-model="TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento"
            :items="TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento"
            :item-value="'id'"
            :item-text="'descricao'"
            label="Tipo de procedimento"
            loading="loadingListaProcedimentos"
          ></v-select>
        </div>
        <div class="col-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="datas"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datas"
                label="Data inicial - Data final"
                prepend-icon="mdi-calendar"
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="datas = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datas"
              no-title
              range
              scrollable
              color="green darken-4"
            >
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false"> Cancelar </v-btn>
              <v-btn class="btn-app-primary" @click="$refs.menu.save(datas)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

      </div>
      <div class="col-4 form-group clearfix mb-3 text-dark">
        <label>Médico</label>
        <v-select
            v-model="medicoId"
            :items="medicos"
            required
            label="Médico"
            item-text="nome"
            item-value="medicoId"
            color="black"
            solo
        >/
          <template v-slot:item="{item}">
            <v-list-item-content>
              <v-list-item-title
                  v-html="item.nome"
              ></v-list-item-title>
              <v-list-item-subtitle class="mt-1"
                                    v-html="item.email"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </div>
      <div class="col-12 row justify-end">
        <div class="d-flex flex-lg-row justify-content-center col-2">
          <v-btn
            :disabled="permiteFiltro"
            color="teal darken-1"
            class="mr-4"
            style="color: white !important"
            @click="limparFiltro"
          >
            Limpar Filtro
          </v-btn>
          <v-btn
            :disabled="permiteFiltro"
            color="success"
            class="mr-4"
            @click="aplicarFiltro"
          >
            Filtrar
          </v-btn>
        </div>
      </div>
    </v-form>
  </v-card>
</template>
<script>
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin";
export default {
  mixins: [TipoProcedimentoMixin],
  props: {
    medicos: Array,
  },
  data: () => ({
    datas: "",
    permiteFiltro: false,
    listaTiposProcedimento: [],
    loadingListaProcedimentos: false,
    menu: false,
    medicoId: 0
  }),
  mounted() {
    this.TIPO_PROCEDIMENTO_MIXIN_atualizarLista();
  },
  methods: {
    limparFiltro() {
      this.datas = ""
      this.$emit("limparFiltro");
    },
    aplicarFiltro() {
      const filtro = {};
      if (this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento) {
        filtro["id_tipo_procedimento"] = this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento;
      }
      if (this.datas) {
        this.datas.sort()
      }
      if (this.datas[0]) {
        filtro["data_inicio"] = this.datas[0];
      }
      if (this.datas[1]) {
        filtro["data_fim"] = this.datas[1];
      } else if (this.datas[0]) {
        filtro["data_fim"] = this.datas[0];
      }

      if(this.medicoId) {
        filtro['medico_id'] = this.medicoId;
      }
      this.$emit("aplicarFiltro", filtro);
    },
  },
};
</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(62, 104, 42) !important;
  border-color: rgb(62, 104, 42);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>
