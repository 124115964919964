import HTTPService from '@/services/http_service.js'

export default class FormularioService extends HTTPService {
    resource = 'consulta'

    getPacienteList (onSucess, onError, onEnd, page, per_page, filter, order, desc) {
        const responseFunctions = {
            onSucess,
            onError,
            onEnd
        }
        let queryparams ={
          page: page,
          per_page: per_page
        }
        if(filter ===''){
          if(order){
            let queryparams ={
              page: page,
              per_page: per_page,
              sort: desc?'nome':'-nome'
            }
            this.request(responseFunctions, 'GET', this.resource+"/medico/1",null,queryparams)
          }else{
            this.request(responseFunctions, 'GET', this.resource+"/medico/1",null,queryparams)
          }
        }else{
          let queryparams ={
            page: page,
            per_page: per_page,
            nome: filter,
          }
          if(order){
            let queryparams ={
              page: page,
              per_page: per_page,
              nome: filter,
              sort: desc?'nome':'-nome'
            }
            this.request(responseFunctions, 'GET', this.resource+"/medico/1",null,queryparams)
          }else{
            this.request(responseFunctions, 'GET', this.resource+"/medico/1", null, queryparams)
          }
        }
      }

} 