<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Perfis</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{'items-per-page-options': [5, 10, 15, 20]}"
      :search="search"
      :headers="headers"
      :items="perfis"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      sort-by="nome"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="allowCadastrarBtn" color="#3E682A" dark class="mb-2" v-bind="attrs" v-on="on">
                Novo Perfil
              </v-btn>
            </template>
            <modal-cadastro v-if="dialogCadastrar"  @close="closeDialogCadastrar" @response="atualizarPerfilPosCadastro($event)"/>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details/>
          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal;">Tem certeza de que deseja excluir este Perfil?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3E682A" text @click="closeDelete">Cancelar</v-btn>
                <v-btn :loading="loadingDelete" class="btn-danger" text style="color: #fff !important;" @click="deleteItemConfirm">Deletar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- DIALOG EDIÇÃO -->
          <v-dialog v-if="dialogEdicao" v-model="dialogEdicao">
            <modal-edicao
                v-if="dialogEdicao"
                :perfilId="editedPerfil"
                @close="dialogEdicao = false"
                @response="atualizarPerfilPosEdicao($event)"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon v-if="allowEditarBtn" small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
<!--        <v-icon v-if="allowDeletarBtn" small @click="deleteItem(item)">-->
<!--          mdi-delete-->
<!--        </v-icon>-->
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn color="#3E682A" dark @click="atualizarPerfis">
          Atualizar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import modalCadastro from '@/components/perfis/modal_cadastro'
import modalEdicao from "@/components/perfis/modal_edicao";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import AuthService from "../services/auth_service";
import endpoints from "../router/endpoints";
import PerfilService from "../services/perfil_service";

export default {
  mixins:[Mixin],
  components: { modalCadastro, modalEdicao },
  data: () => ({
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    editedPerfil: {},
    search: '',
    totalList:10,
    options:{
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc:false,
    },
    headers: [
      {
        text: 'Perfil',
        align: 'start',
        sortable: true,
        value: 'descricao',
      },
      {text: 'Ações', align: 'end', value: 'actions', sortable: false},
    ],
    perfis: [],
    loading: false,
    loadingDelete: false,
    services: {
      authService: AuthService.build(),
      perfilService: PerfilService.build()
    },
    perfilIdToDelete: 0
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
     options:{
      handler (){
        this.options.desc = this.options.sortDesc[0]??false
        this.atualizarPerfis();
      },
      deep:true
    },
    search(){
      this.atualizarPerfis();
    }
  },

  mounted() {
    this.atualizarPerfis()
  },
  computed: {
    allowCadastrarBtn() {
      return this.services.authService.temPermissao(endpoints["PerfilController::POST->register"]);
    },
    allowEditarBtn() {
      return this.services.authService.temPermissao(endpoints["PerfilController::POST->updatePerfilData/$1"]);
    },
    allowDeletarBtn() {
      return false; // Perfis não podem ser deletados.
    },
  },
  methods: {
    atualizarPerfilPosCadastro() {
      this.atualizarPerfis()
      this.closeDialogCadastrar()
    },
    atualizarPerfilPosEdicao() {
      this.dialogEdicao = false
      this.atualizarPerfis()
    },
    async atualizarPerfis() {
      this.loading = true;
      this.services.perfilService.getPerfilPaginatedList({
        page:this.options.page,
        per_page: this.options.itemsPerPage,
        search: this.search,
        sort: this.options.ordenacao,
        desc: this.options.desc
      })
          .then(async (response) => {
            if (response.status !== 200) return;
            const {data} = await response.json()
            const {pagination, perfis} = data;
            this.totalList = pagination.num_rows;
            this.perfis = perfis.map(perfil => ({
              perfilId: ~~perfil.perfil_id,
              descricao: (perfil.perfil_nome || '').toUpperCase()
            }));
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false;
          })
      // this.loading = true
      // const perfiservice = new Perfiservice()
      // this.loading = true;
      // this.perfis=[];
      // const onSucess = (status) => (body) => {
      //   if (status === 200) {
      //    this.totalList = body.data.pagination.num_rows
      //    this.perfis = body.data.perfis.map((perfil) => {
      //       return perfil;
      //     });
      //   } else {
      //     console.log(body);
      //   }
      // };
      // const onError = (error) => {
      //   console.log(error);
      // };
      // const onEnd = () => {
      //   this.loading = false;
      // };
      // perfiservice.getAll(onSucess, onError, onEnd,this.options.page, this.options.itemsPerPage, this.search, this.options.ordenacao, this.options.desc);
    },
    async editItem(item) {
      this.editedPerfil = item.perfilId || 0;
          // JSON.stringify(item)
      // )
      // const body = await this.services.perfilService.getDetalhesByPerfilId(item.perfilId);
      // if (!body) return;
      // this.editedPerfil = body;
      this.dialogEdicao = true
    },

    deleteItem({perfilId}) {
      // this.editedPerfil = JSON.parse(JSON.stringify({perfilId}))
      this.perfilIdToDelete = perfilId;
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      this.services.perfilService.deletarPerfil(this.perfilIdToDelete)
        .then(async ({status}) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: status === 200 ? 'O perfil foi deletado com sucesso.' : this.$global.messages.internalServerError,
              color: status === 200 ? 'sucess' : 'error'
            })
            status === 200 && await this.atualizarPerfis();
        })
        .catch(e => console.error(e))
        .finally(() => {
          this.loadingDelete = false;
          this.closeDelete()
        })
      ;
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
}
</script>
