<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="px-2 text-right">
      <v-btn text @click="editandoSessao1 = !editandoSessao1">
        <v-icon v-if="editandoSessao1"> mdi-close </v-icon>
        <v-icon v-else> mdi-pencil </v-icon>
      </v-btn>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label for="nome">Nome Completo</label>
        <v-text-field
          :disabled="!editandoSessao1"
          v-model="nome"
          :type="'text'"
          :rules="DADOS_CADASTRAIS_MIXIN_validationNome"
          placeholder="Seu nome completo"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-6">
        <label for="Telefone01">Telefone 1:</label>
        <v-text-field
          :disabled="!editandoSessao1"
          v-mask="'(##)#####-####'"
          v-model="telefone_1"
          :rules="DADOS_CADASTRAIS_MIXIN_validationTelefone1"
          :type="'text'"
          placeholder="(99) 99999-9999"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
      <div class="col-md-6">
        <label for="Telefone02">Telefone 2:</label>
        <v-text-field
          :disabled="!editandoSessao1"
          v-mask="'(##)#####-####'"
          v-model="telefone_2"
          :rules="DADOS_CADASTRAIS_MIXIN_validationTelefone2"
          :type="'text'"
          placeholder="(99) 99999-9999"
          style="padding-top: 0 !important"
        ></v-text-field>
      </div>
    </div>
    <div class="px-2 text-right">
      <v-btn
        :disabled="!editandoSessao1"
        :loading="DADOS_CADASTRAIS_MIXIN_loading"
        color="success"
        @click="salvar"
      >
        Salvar
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
  mixins: [DadosCadastraisMixin],
  data: () => ({
    editandoSessao1: false,
    nome: "",
    email: "",
    telefone_1: "",
    telefone_2: "",
    foto: "",
    valid: false,
  }),
  mounted() {
    this.nome = this.$_GETTERS_usuario.dados.nome;
    this.email = this.$_GETTERS_usuario.dados.email;
    this.telefone_1 = this.$_GETTERS_usuario.dados.telefone_1;
    this.telefone_2 = this.$_GETTERS_usuario.dados.telefone_2;
  },
  methods: {
    salvar() {
      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      const dados = {};
      ["nome", "email", "telefone_1", "telefone_2", "foto"].forEach((param) => {
        if (this[param] !== this.$_GETTERS_usuario.dados[param]) {
          dados[param] = this[param];
        }
      });

      const formdata = new FormData();
      const foto = document.querySelector("input[type=file]").files[0];

      formdata.append("nome", this.nome);
      formdata.append("email", this.email);
      formdata.append("telefone_1", this.telefone_1);
      formdata.append("telefone_2", this.telefone_2);
      formdata.append("foto", foto);
      // TODO: Implementar validação de dados cadastrais
      this.DADOS_CADASTRAIS_MIXIN_updateDadosCadastraisFile(formdata);
      this.$emit('reloadData')
    },
  },
};
</script>