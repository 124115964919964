<template>
  <v-simple-table class="mt-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Senha</th>
          <th class="text-left">Nome</th>
          <th class="text-left">Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="paciente in pacientes"
          :key="paciente.nome"
          class="text-left"
        >
          <td>{{ paciente.Senha }}</td>
          <td>{{ paciente.UsuarioSenha }}</td>
          <td>{{ paciente.ClassificacaoAtendimento }}</td>
          <!-- <td>
            <v-btn
              small
              depressed
              color="success"
              @click="atender(paciente)"
            >
              Atender
            </v-btn>
          </td> -->
        </tr>
      </tbody>
    </template>











  </v-simple-table>
</template>

<script>
import { global } from "@/config/constants";
import axios from "axios";

import ProcedimentoService from "@/services/procedimento_service";
import AgendamentoService from "@/services/agendamento_service";
export default {
  data() {
    return {
      tokenChamefacil: "",
      pacientes: [],
      dialogAtender: false,
      pacienteEmAtendimento: {},
      procedimentos: [],
      procedimentosService: ProcedimentoService.build(),
      agendamentoService: AgendamentoService.build(),
      procedimento: {},
      procedimentoLoading: false
    };
  },
  async beforeMount() {
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
    this.listarFilaChamefacil();
  },
  watch: {
    pacientes() {
      setTimeout(() => {
        this.listarFilaChamefacil();
      }, 15000);
    },
  },
  methods: {
    listarFilaChamefacil() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/atendimento/listarsenhasinternaspendentesV2",
          { headers }
        )
        .then((resp) => {
          // console.log(resp.data.Senha);
          this.pacientes = resp.data.Senha.filter(s => s.PetCodigo);
        });
    },
    chamarSenha(idChamada) {
      this.$emit("chamarSenha", idChamada);
    },
  },
};
</script>

<style scoped></style>
