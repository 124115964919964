<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de medicamento</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="nome" label="Nome"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="quantidade" label="Quantidade"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="posologia" label="Posologia"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="cadastrar" :disabled="!nome">
          Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ExameService from '@/services/medicamento_service.js'
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins:[Mixin],
  data: () => ({
    nome: '',
    quantidade: '',
    posologia: '',
    loading: false,
  }),
  methods: {
    cadastrar() {
      const medicamento = {
        nome: this.nome,
        quantidade: this.quantidade,
        posologia: this.posologia,
        id_clinica: 1,
        // criado_por:this.$_GETTERS_usuario.id
      }
      const medicamentoService = new ExameService()
      this.loading = true
      medicamentoService.cadastrar({
        onSucess: status => body => {
          if (status === 200) {
            medicamento.id = body.data.id_new_medicamento // Completar com a API
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: status === 200 ? 'sucess' : 'error'
          })
          this.$emit('response', medicamento)
        },
        onError: error => {
          console.error(error)
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        },
        onEnd: () => {
          this.loading = false
        },
      },
      medicamento,
      )
    },
  },
}
</script>