import HTTPService from "@/services/http_service.js";
// import { global } from "@/config/constants";

export default class ComandaService extends HTTPService {
    static build() {
        return new ComandaService();
    }

    async getComandasByClienteId(clienteId) {
        return await this.get(`comanda/cliente/${clienteId}`).then(
            async (response) => {
                if (response.status !== 200) return [];
                const {data} = await response.json();
                return data;
            }
        );
    }

    async getComandaItensByComandaId(comandaId) {
        return await this.get(`comanda/${comandaId}/itens`).then(
            async (response) => {
                if (response.status !== 200) return [];
                const {data} = await response.json();
                return data;
            }
        );
    }

    pagarItemComanda(comandaId, itemId) {
        return this.post(`comanda/${comandaId}/item/${itemId}/marcar-pago`);
    }

    async fecharComanda(comandaId) {
        try {
            const response = await this.post(`comanda/${comandaId}/fechar`);
            console.log({response});
            return response.status === 200;
        } catch (e) {
            return false;
        }
    }
}
