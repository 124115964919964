<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Receita</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
            <v-text-field
              v-model="titulo"
              class="col-10"
              label="Titulo"
              placeholder="Pré-operatório"
            ></v-text-field>
           <v-select
            class="col-10"
            v-model="med"
            :items="medicamentos"
            :loading="isLoading"
            item-text="nome"
            label="Medicamento"
            return-object
          ></v-select>
          <v-text-field
            v-model="qntd"
            class="col-10"
            label="Quantidade"
          ></v-text-field>
          <v-text-field
            v-model="dosagem"
            class="col-10"
            label="Posologia"
          ></v-text-field>
          <button
            class="btn btn-app-primary btn-padding font-weight-bold"
            @click="add()"
          >
            Adicionar
          </button>
          <div class="d-flex flex-column" v-if="receita.length >0">
            <u v-for="(item, i) in receita" :key="i">
              <li class="d-flex flex-column resp">
                <h4>{{ item.titulo }}</h4>
                <p><b>{{ i + 1 }}-</b> {{ item.medicamento }}_______{{ item.quantidade}} <v-btn @click="remover_item(i)" class="ml-3" small icon ><v-hover v-slot="{ hover }"><v-icon medium :color="hover?'#F44336':'gray'" > fas fa-times</v-icon></v-hover></v-btn>  <br>{{ item.posologia}} </p>
              </li>
            </u>
          </div>
        </v-container>
         <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
               @click="$emit('close')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-app-primary btn-padding font-weight-bold"
              @click="gerarPdf()"
            >
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MedicamentoService from '@/services/medicamento_service.js'

export default {
  beforeMount(){
    this.loadMedicamentos()
  },
  data: () => ({
    receita:[],
    med:{},
    isLoading:false,
    medicamentos:[],
    medicamento:"",
    qntd:"",
    dosagem:"",
    titulo:"",
  }),
  watch:{
    med(){
      this.receita_fill()
    },
    medicamento(e){
      this.medicamento = e
      console.log(this.medicamento)
    },
    qntd(e){
      this.qntd = e
      console.log(this.qntd)
    },
    dosagem(e){
      this.dosagem = e
      console.log(this.dosagem)
    },
  },
  methods:{
    remover_item(i){
      this.receita.splice(i, 1)
    },
    receita_fill(){
      this.dosagem = this.med.posologia
      this.qntd = this.med.quantidade
      this.medicamento = this.med.nome
    },
    loadMedicamentos(){
      const medicamentoService = new MedicamentoService()
      this.isLoading = true
      medicamentoService.getList({
        onSucess:(status) => (body)=>{
          if (status === 200) {
            this.medicamentos = body.data.medicamentos   
          }
        },
        onError:(error)=>{
          console.log(error)
        },
        onEnd:()=>{
          this.isLoading = false
        }
      })

    },
    add(){
      this.receita.push({titulo:this.titulo, medicamento: this.medicamento, quantidade: this.qntd, posologia: this.dosagem})
      this.titulo = ""
      this.medicamento = ""
      this.qntd = ""
      this.dosagem = ""
      this.med = {}
    },
    gerarPdf(){
      if (this.receita =="") {
        console.log("Receita é obrigatória")
        return
      }
      this.$emit("gerarPDF", {
        doc:"receita",
        receita: this.receita
      })
      this.receita = []
      this.titulo = ""
      this.medicamento = ""
      this.qntd = ""
      this.dosagem = ""
    }
  }
};
</script>